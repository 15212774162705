import { IGroupOrPerson } from "../../../components/peoplePickerControl/SPPeopleSearchService";
import Utilities from "../../helpers/utilities";
import { ExportDestination, IExportTaskDestination } from "../../interfaces/IExportTaskDestination";
import { PlannerBucket } from "../../interfaces/IPlannerPlan";
import moment from "moment";
import { GetGraphAccessToken } from "../HttpClientService/HttpClientService";
import HttpHeaderHelper from "../../helpers/HttpHeadersHelper";
import axios from "axios";

export default class PlannerService {
  public static async getPlans(groupId: string): Promise<IExportTaskDestination[]> {
    const plans: IExportTaskDestination[] = [];

    try {
      const accessToken = await GetGraphAccessToken();

      const reqHeaders = HttpHeaderHelper.getHeaders(undefined, false, false, accessToken);

      const plansJson = await axios.get(`https://graph.microsoft.com/v1.0/groups/${groupId}/planner/plans?$select=id,title`, { headers: reqHeaders });

      if(plansJson.data.value && plansJson.data.value.length > 0){
        plansJson.data.value.forEach(element => {
            plans.push({ Id: element.id, Title: element.title, Destination: ExportDestination.Planner });
        });
      }
    }catch(error){
        console.error(error);
    }

    return plans;
  }

  public static async getPlanBuckets(planId: string): Promise<PlannerBucket[]> {
    const buckets: PlannerBucket[] = [];

    try {
      const accessToken = await GetGraphAccessToken();

      const reqHeaders = HttpHeaderHelper.getHeaders(undefined, false, false, accessToken);

      const bucketsJson = await axios.get(`https://graph.microsoft.com/v1.0/planner/plans/${planId}/buckets`, { headers: reqHeaders });

      if(bucketsJson.data.value && bucketsJson.data.value.length > 0){
        bucketsJson.data.value.forEach((bucket) => {
          buckets.push({ id: bucket.id, name: bucket.name, planId: bucket.planId, orderhint: bucket.orderHint})
        });
      }
    }catch(error){
        console.error(error);
    }

    return buckets;
  }

  public static async savePlannerTask(planId: string, bucketId: string, taskName: string, taskNotes: string, messageLink: string, users: IGroupOrPerson[], taskDueDate: Date): Promise<boolean> {
    let result = false;

    if(!planId || planId.length === 0 || !bucketId || bucketId.length === 0 || !taskName || taskName.length === 0){
      return result;
    }

    try {
      const accessToken = await GetGraphAccessToken();

      const reqHeaders = HttpHeaderHelper.getHeaders(undefined, false, false, accessToken);

      const taskBody = {
        planId: planId,
        bucketId: bucketId,
        title: taskName,
        dueDateTime: null,
        assignments: {}
      };

      if(users && users.length > 0){
        users.forEach((user) => {
          taskBody.assignments[user.objectId] = {
            "@odata.type": "#microsoft.graph.plannerAssignment",
            orderHint: " !",
          };
        });
      }
      
      if(taskDueDate){
        taskBody.dueDateTime = moment(taskDueDate).toISOString();
      }
      
      const taskJson = await axios.post(`https://graph.microsoft.com/v1.0/planner/tasks`, taskBody, { headers: reqHeaders });

      if(taskJson.data.id && taskJson.data.id.length > 0){
        const taskDetailsJson = await axios.get(`https://graph.microsoft.com/v1.0/planner/tasks/${taskJson.data.id}/details`, { headers: reqHeaders });

        const taskEtag = taskDetailsJson.data["@odata.etag"];

        const taskDetailsBody = {
          description: taskNotes,
          references: {},
          previewType: "noPreview"
        };
    
        taskDetailsBody.references[Utilities.encodeForOData(messageLink)] = {
          "@odata.type": "microsoft.graph.plannerExternalReference",
          alias: "View message in Teams",
          previewPriority: " !",
          type: "Other"
        };

        const completeHeaders = reqHeaders.set('If-Match', taskEtag).set('Prefer', 'return=representation');        

        const taskDetailsUpdateJson = await axios.patch(`https://graph.microsoft.com/v1.0/planner/tasks/${taskJson.data.id}/details`, taskDetailsBody, { headers: completeHeaders });

        result = taskDetailsUpdateJson.data.id && taskDetailsUpdateJson.data.id.length > 0;
      }
    }catch(error){
        console.error(error);
    }

    return result;
  }
}