import * as React from "react";
import { MessageBar, MessageBarType } from '@fluentui/react/lib/MessageBar';
import { MessageBarButton } from '@fluentui/react/lib/Button';

//import { ApplicationCustomizerContext } from "@microsoft/sp-application-base";
//import { WebPartContext } from "@microsoft/sp-webpart-base";

export interface IErrorBoundaryState {
    hasError: boolean;
    errorMsg: string;
}

export interface IErrorBoundaryProps {
    hasError: boolean;
    componentName: string;
    componentId: string;
    logConsole?: boolean;
}

export class RTCErrorBoundary extends React.Component<IErrorBoundaryProps, IErrorBoundaryState> {

    constructor(props) {
        super(props);
        this.state = {
            hasError: false,
            errorMsg: '',
        };
    }
    
    public static getDerivedStateFromError(error):{hasError: boolean;} {
        return { hasError: true };
    }

    public componentDidCatch(error: Error, errorInfo: React.ErrorInfo):void {
        //log the error to an error reporting service
        let myErrorMsg = "Error in " + this.props.componentName + "<br />";
        if (error && error.message) {
            myErrorMsg = error.message + '<br />';

        }
        if (errorInfo && errorInfo.componentStack) {
            //in t in div in o in Styledo in div in Stack in t in div in RuleEngine in CN365ErrorBoundary in t in div in CN365Wrapper in div in AdminPage in div in t in Admincenter
            //<- nav <- Styledt <- Styledt <- StackItem <- StackItem
            let myMsg = "";
            const myHelper = errorInfo.componentStack.split("\n");
            myHelper.forEach(myText => {
                const myTrim = myText.trim();
                if (myTrim !== "in t" &&
                    myTrim !== "in div" &&
                    myTrim !== "in nav" &&
                    myTrim !== "in Styledt" &&
                    myTrim !== "in StackItem" &&
                    myTrim !== "in Stack.Item" &&
                    myTrim !== "in o" &&
                    myTrim !== "in Styledo" &&
                    myTrim !== "in Stack" &&
                    myTrim !== "in CN365ErrorBoundary" &&
                    myTrim !== "in CN365Wrapper" &&
                    myTrim.length > 0
                ) {
                    if (myMsg.length > 0){
                        myMsg += " <- ";
                    }
                    
                    myMsg += myTrim.replace("in ", "");
                }
            });
            myErrorMsg += myMsg + "<br />";
        }
        this.setState({ errorMsg: myErrorMsg });

        //const myContext = React.useContext(LoggingContext);


        //this.context.CN365LoggingContext.loggingService.logError(error, errorInfo);
        //CN365Logging.createLoggingManager(this.context.context.pageContext.aadInfo.tenantId.toString(), this.context.pageContext.web.absoluteUrl);
        if (this.props.logConsole === undefined || (this.props.logConsole && error)){
            console.log(error);
        }
    }

    public copyCorrelationId = ():void => {
        //navigator.clipboard.writeText(this.props.loggingService.getCorrelationId()); // eslint-disable-line @typescript-eslint/no-floating-promises
    }
    public copyErrorMessage = ():void => {
        navigator.clipboard.writeText(this.state.errorMsg); // eslint-disable-line @typescript-eslint/no-floating-promises
    }
    public cancel = ():void => {
        this.setState({ hasError: false});
    }

    public render():React.ReactNode {

        if (this.state.hasError) {
            return <>
                <MessageBar messageBarType={MessageBarType.error}
                    isMultiline={true}
                    actions={
                        <div>
                            <MessageBarButton onClick={this.cancel}>{"Cancel"}</MessageBarButton>
                            <MessageBarButton onClick={this.copyCorrelationId}>{"Copy correlationId to clipboard"}</MessageBarButton>
                            <MessageBarButton onClick={this.copyErrorMessage}>{"Copy Error-Message to clipboard"}</MessageBarButton>
                        </div>
                    }>
                    <div dangerouslySetInnerHTML={{ __html: this.state.errorMsg }} />
                    {/* {this.state.errorMsg != null ? this.state.errorMsg : ""} */}
                </MessageBar>
            </>;
        }
        return this.props.children;
    }
}