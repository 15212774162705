import { TeamsUserCredential } from "@microsoft/teamsfx";
import { AppConstants } from "../../Constants/AppConstants";
import config from "../../helpers/config";
import { Providers, ProviderState } from "@microsoft/mgt-element";
import { TeamsFxProvider } from "@microsoft/mgt-teamsfx-provider";

/* export async function GetAxiosGraphClient(accessToken: string): Promise<React.MutableRefObject<Client>> { 
    const graphClient: React.MutableRefObject<Client | null> = useRef<Client | null>(null);

    try{
        const oboAuthConfig: OnBehalfOfCredentialAuthConfig = {
            authorityHost: config.authorityHost,
            clientId: config.clientId,
            tenantId: config.tenantId,
            clientSecret: config.clientSecret,
          };

        const oboCredential = new OnBehalfOfUserCredential(accessToken, oboAuthConfig);

        const authProvider = new TokenCredentialAuthenticationProvider(oboCredential, { scopes: ["https://graph.microsoft.com/.default"] });

        const graph = Client.initWithMiddleware({
            authProvider: authProvider,
        });

        graphClient.current = graph;        

        return graphClient;
    }catch(error){
        console.error(error);

        return undefined;
    }    
} */

//Original
/* export async function GetAccesstoken(teamsUserCredential: TeamsUserCredential, scope?: string): Promise<string> {
    if(!teamsUserCredential){
        return undefined;
    }

    let scopeToken = scope ?? "";
    
    try{
        let accessToken = await teamsUserCredential.getToken(scopeToken); // Get SSO token
        
        return accessToken?.token;
    }catch(error){
        console.error(error);

        return DoLogin(teamsUserCredential);
    }   
} */

export async function GetUserImpersonationAccessToken(initialize?: boolean): Promise<string> {
    const credential = new TeamsUserCredential({
        initiateLoginEndpoint: config.initiateLoginEndpoint,
        clientId: config.clientId,
    });
    
    if(initialize){
        const provider = new TeamsFxProvider(credential, AppConstants.simplifiedScopes);
    
        Providers.globalProvider = provider;
    }    

    let consentNeeded = false;

    let accessToken = "";

    const apiEndpoint = `${config.initiateLoginEndpoint.replace('https://', 'api://').replace('/auth-start.html', '')}/${config.clientId}/access_as_user`;
    
    try {      
        accessToken = (await credential.getToken(apiEndpoint)).token;
    } catch (error) {
        accessToken = await DoLogin(credential, [apiEndpoint]);
        
        consentNeeded = true;
    }

    if(initialize){
        Providers.globalProvider.setState(consentNeeded ? ProviderState.SignedOut : ProviderState.SignedIn);
    }
    
    return accessToken;
}

export async function GetGraphAccessToken(initialize?: boolean): Promise<string> {
    const credential = new TeamsUserCredential({
        initiateLoginEndpoint: config.initiateLoginEndpoint,
        clientId: config.clientId,
    });
    
    if(initialize){
        const provider = new TeamsFxProvider(credential, AppConstants.simplifiedScopes);
    
        Providers.globalProvider = provider;
    }    

    let consentNeeded = false;

    let accessToken = "";
    
    try {      
        accessToken = (await credential.getToken(AppConstants.simplifiedScopes)).token;
    } catch (error) {
        accessToken = await DoLogin(credential, AppConstants.simplifiedScopes);
      
        consentNeeded = true;
    }

    if(initialize){
        Providers.globalProvider.setState(consentNeeded ? ProviderState.SignedOut : ProviderState.SignedIn);
    }
    
    return accessToken;
}

export async function GetSharePointAccessToken(initialize?: boolean): Promise<string> {
    const credential = new TeamsUserCredential({
        initiateLoginEndpoint: config.initiateLoginEndpoint,
        clientId: config.clientId,
        
    });
    
    if(initialize){
        const provider = new TeamsFxProvider(credential, AppConstants.sharePointScopes);
    
        Providers.globalProvider = provider;
    }

    let consentNeeded = false;

    let accessToken = "";
    
    try {      
        accessToken = (await credential.getToken(AppConstants.sharePointScopes)).token;
    } catch (error) {
        accessToken = await DoLogin(credential, AppConstants.sharePointScopes, '00000003-0000-0ff1-ce00-000000000000');

        consentNeeded = true;
    }

    if(initialize){
        Providers.globalProvider.setState(consentNeeded ? ProviderState.SignedOut : ProviderState.SignedIn);
    }

    return accessToken;
}

async function DoLogin(teamsUserCredential: TeamsUserCredential, scopes?: string[], resource?: string): Promise<string> {
    const localScopes = scopes && scopes.length > 0 ? scopes : AppConstants.simplifiedScopes;

    if(resource){
        await teamsUserCredential.login(localScopes, [resource]);
    }else{
        await teamsUserCredential.login(localScopes);
    }    

    return (await teamsUserCredential.getToken(localScopes)).token;
}
