import * as React from 'react';
import { RTCErrorBoundary } from './RTCErrorBoundary';
import RTCContext from './RTCContext';
import { IRTCContext } from './RTCContext';
import { FluentProvider, Theme as ThemeV9 } from '@fluentui/react-components';
import { useContext } from 'react';
import { app } from '@microsoft/teams-js';

export interface IRTCWrapper {
    siteUrl:string;
    sitePath:string;
    siteId:string;
    tenantId?: string;
    groupId: string;
    upn: string;
    userDisplayName?: string;
    teamsContext: app.Context;
    themeV9?: ThemeV9;
    themeName?: string;
    componentId?: string;
    componentName?: string;
    localization: IRTCresources;
}

export const RTCWrapper: React.FunctionComponent<IRTCWrapper> = (props) => {
    const rtcContext = useContext(RTCContext);

    const tenantId = props.tenantId ?? rtcContext.tenantId;
    const siteUrl = props.siteUrl ?? rtcContext.siteUrl;

    const _RTCContext: IRTCContext = {
        tenantId: tenantId,
        siteUrl: siteUrl,
        sitePath: props.sitePath,
        siteId: props.siteId,
        groupId: props.groupId,
        upn: props.upn,
        userDisplayName: props.userDisplayName,
        teamsContext: props.teamsContext,
        componentId: props.componentId ? props.componentId : rtcContext.componentId,
        themeV9: props.themeV9 ? props.themeV9 : rtcContext.themeV9,
        themeName: props.themeName ? props.themeName : rtcContext.themeName,
        componentName: props.componentName ? props.componentName : rtcContext.componentName,
        localization: props.localization
    };

    return <>
        <RTCContext.Provider value={_RTCContext}>
            <FluentProvider theme={props.themeV9 ? props.themeV9 : rtcContext.themeV9}>
                <RTCErrorBoundary
                    hasError={false}
                    logConsole={false}
                    componentName={props.componentName ? props.componentName : rtcContext.componentName}
                    componentId={props.componentId ? props.componentId : rtcContext.componentId}>
                        {props.children}
                </RTCErrorBoundary>
            </FluentProvider>
        </RTCContext.Provider>
    </>;
};
