/* eslint-disable no-mixed-operators */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/no-floating-promises */
import { isEqual } from 'lodash';
import { UrlUtilities } from '../../helpers/UrlUtilities';
import { GetSharePointAccessToken } from '../HttpClientService/HttpClientService';
import axios, { AxiosHeaders } from 'axios';

interface IColorObject {
	R: number;
	G: number;
	B: number;
	A: number;
}

export enum WSInfoDetail{
    WebInfo = 1 << 0, // 1
    CN365Info = 1 << 1,  // 2
    SiteInfo= 1 << 2, //4
    GroupInfo= WSInfoDetail.SiteInfo | 1 << 3
}

interface WSInfoCacheItem{
    WebUrl:string;
    WSInfo:WSInfo;
    WebInfoTimeStamp:number;
    CN365InfoTimeStamp:number;
    SiteInfoTimeStamp:number;
    GroupInfoTimeStamp:number;
}

export interface WSInfo{   
    WebInfo?:WSInfoWebDetails;
    CN365Info?:WSInfoCn365Details;
    SiteInfo?:WSInfoSiteDetails;
    GroupInfo?:WSInfoGroupDetails;
}
export interface WSInfoWebDetails{
    WebId:string;
    Title:string;
    Description:string;
    SiteLogoUrl:string;
    Color:string;
    Acronym:string;
    Prefix:string;
    WebTemplate:string;
    Language:number;
    IsMultilingual:boolean;
    SupportedUILanguageIds:Array<number>;
}
export interface WSInfoCn365Details{
    TemplateId:string;
    BaseSiteType:string;
    SiteType:string;
    SiteStatus:string;
    PortfolioHostUrl:string;
    IsCN365Site:string;
    SitePrivacy:string;
}

export interface WSInfoSiteDetails{
    SiteId:string;
    IsHubSite:boolean;
    GeoLocation:string;
    ShareByEmailEnabled:boolean;
    ShareByLinkEnabled:boolean;
    HubSiteId:string;
    GroupId:string;
    RelatedGroupId:string;
    SensitivityLabelId:string;
    SensitivityLabel:string;
    Classification:string;
    ChannelGroupId:string;
    WriteLocked:boolean;
}

export interface WSInfoGroupDetails{
        Alias:string;
        AllowToAddGuests:boolean;
        AssignedLabels:string;
        CalendarUrl:string;
        Classification:string;
        Description:string;
        DisplayName:string;
        DocumentsUrl:string;
        EditGroupUrl:string;
        InboxUrl:string;
        IsDynamic:boolean;
        IsPublic:boolean;
        Mail:string;
        NotebookUrl:string;
        PeopleUrl:string;
        PictureUrl:string;
        PrincipalName:string;
        TeamsUrl:string;
}

interface CacheKillItem{
    value:string;
    lastChecked:number;
}

class WSInfoStore {
    private readonly _dbp: Promise<IDBDatabase>;
  
    constructor(dbName = 'CN365WSInfo', readonly storeName = 'WSInfoCache') {      
      this._dbp = new Promise((resolve, reject) => {
        const openreq = indexedDB.open(dbName, 1);
        openreq.onerror = () => reject(openreq.error);
        openreq.onsuccess = () => resolve(openreq.result);
  
        // First time setup: create an empty object store
        openreq.onupgradeneeded = () => {
          openreq.result.createObjectStore(storeName);
        };
      });
    }
  
   public _withIDBStore(type: IDBTransactionMode, callback: ((store: IDBObjectStore) => void)): Promise<void> {
      return this._dbp.then(db => new Promise<void>((resolve, reject) => {
        const transaction = db.transaction(this.storeName, type);
        transaction.oncomplete = () => resolve();
        transaction.onabort = transaction.onerror = () => reject(transaction.error);
        callback(transaction.objectStore(this.storeName));
      }));
    }
  }

class WSInfoCacheDB{
    private static store: WSInfoStore;    

    private static getStore():WSInfoStore{
        if(!this.store){
            this.store=new WSInfoStore();
        }
         return this.store;
        
    }
    
    public static async get<Type>(key: IDBValidKey): Promise<Type> {
      let req: IDBRequest;
      return this.getStore()._withIDBStore('readonly', store => {
        req = store.get(key);
      }).then(() => req.result);
    }
    
    public static async set(key: IDBValidKey, value: any): Promise<void> {
      return this.getStore()._withIDBStore('readwrite', store => {
        store.put(value, key);
      });
    }
    
    public static async del(key: IDBValidKey): Promise<void> {
      return this.getStore()._withIDBStore('readwrite', store => {
        store.delete(key);
      });
    }
    
    public static async clear(): Promise<void> {
      this.getStore()._withIDBStore('readwrite', store => {
        store.clear();
      });
    }
    
    public static async keys(): Promise<IDBValidKey[]> {
      const keys: IDBValidKey[] = [];
    
      return this.getStore()._withIDBStore('readonly', store => {
        // This would be store.getAllKeys(), but it isn't supported by Edge or Safari.
        // And openKeyCursor isn't supported by Safari.
        ((store as any).openKeyCursor || (store as any).openCursor).call(store).onsuccess = function() {
          if (!this.result) return;
          keys.push(this.result.key);
          this.result.continue();
        };
      }).then(() => keys);
    }


}

class WSInfoCache{
    public static async tryLoadFromCache(webUrl:string):Promise<WSInfoCacheItem>{
        
        try{
            webUrl=UrlUtilities.removeEndSlash(webUrl.toLowerCase());
            const data=await WSInfoCacheDB.get<WSInfoCacheItem>(webUrl);       
            if(data){
                return data;
            }
            else{
                return undefined;
            }
        }
        catch(error){
            //we are probably inprivate...
            return undefined;
        }
       
    }
    
    public static async storeInCache(webUrl:string,wsInfo:WSInfoCacheItem):Promise<void>{

        try{
            webUrl=UrlUtilities.removeEndSlash(webUrl.toLowerCase());
            await WSInfoCacheDB.set(webUrl,wsInfo);            
            //run cleanupjob after storing values in cache
            //this.cleanUp();           
        }
        catch(error){
            //we are probably inprivate...
            return undefined;
        }
            
    }
}


export class WSInfoService {
     public async checkGlobalCacheKillswitch(webAbsoluteUrl: string):Promise<void>{
        try{

        const localCacheKillInfo=await WSInfoCacheDB.get<CacheKillItem>("WSInfoGlobalCacheKillSwitch");
        
        if(localCacheKillInfo&&localCacheKillInfo.lastChecked){
            const oneHourinMS = 1 * 60 * 60 * 1000;                      
            const now=new Date().getTime();
            //we will check every hour if we should clear the cache
            if((now-localCacheKillInfo.lastChecked)>oneHourinMS){
                const accessToken = await GetSharePointAccessToken();

                const requestHeaders: AxiosHeaders = new AxiosHeaders();
                
                requestHeaders.set('Authorization', `Bearer ${accessToken}`);
                requestHeaders.set('odata-version', '3.0');
                requestHeaders.set('Accept', 'application/json;odata=verbose');
                requestHeaders.set('Content-type', 'application/json;odata=verbose');

                const respJson = await axios.get(webAbsoluteUrl+"/_api/web/getstorageentity('WSInfoGlobalCacheKillSwitch')", { headers: requestHeaders });
                if(respJson.status === 200){
                    const resp = await respJson.data;
                    if(resp&&resp.Value){
                        const globalCacheKillValue=resp.Value;
                        if(globalCacheKillValue!==localCacheKillInfo.value){
                            await WSInfoCacheDB.clear();
                            localCacheKillInfo.lastChecked=new Date().getTime();
                            localCacheKillInfo.value=resp.Value;
                            await WSInfoCacheDB.set("WSInfoGlobalCacheKillSwitch",localCacheKillInfo); 
                        }
                        else{
                            localCacheKillInfo.lastChecked=new Date().getTime();
                            await WSInfoCacheDB.set("WSInfoGlobalCacheKillSwitch",localCacheKillInfo); 
                        }
                    }
                    else{
                        localCacheKillInfo.lastChecked=new Date().getTime();
                        await WSInfoCacheDB.set("WSInfoGlobalCacheKillSwitch",localCacheKillInfo);  
                    }
                }
                else{
                    localCacheKillInfo.lastChecked=new Date().getTime();
                    await WSInfoCacheDB.set("WSInfoGlobalCacheKillSwitch",localCacheKillInfo); 
                }
            }
            else{
                localCacheKillInfo.lastChecked=new Date().getTime();
                await WSInfoCacheDB.set("WSInfoGlobalCacheKillSwitch",localCacheKillInfo); 
            }

        }
        else{            
            const cachkillinfo:CacheKillItem={
                value:new Date().getTime().toString(),
                lastChecked:new Date().getTime()
            };
            await WSInfoCacheDB.set("WSInfoGlobalCacheKillSwitch",cachkillinfo); 
            }
            
        }
    
    catch(error){
        console.log("error checking globalcachekillswitch");
        console.log(error);
    }
    }

    public async updateGlobalCacheKillswitch(webAbsoluteUrl:string):Promise<void>{
        const accessToken = await GetSharePointAccessToken();

        const requestHeaders: AxiosHeaders = new AxiosHeaders();
        
        requestHeaders.set('Authorization', `Bearer ${accessToken}`);
        requestHeaders.set('odata-version', '3.0');
        requestHeaders.set('Accept', 'application/json;odata=verbose');
        requestHeaders.set('Content-type', 'application/json;odata=verbose');

        const respJson = await axios.get(webAbsoluteUrl+"/_api/SP_TenantSettings_Current", { headers: requestHeaders });

        const resp = await respJson.data;

        if (respJson.status === 200){
            const appCatalog = resp.CorporateCatalogUrl;
            const storageEntity = {
                key:"WSInfoGlobalCacheKillSwitch",
                value:new Date().getTime().toString()

            };

            const appCatalogBaseUrl = `${appCatalog}/_api/web`;

            await axios.post(`${appCatalogBaseUrl}/SetStorageEntity`, storageEntity, { headers: requestHeaders });
        }
    }
     
     public async getWSInfo(webAbsoluteUrl:string, wsInfoDetail:WSInfoDetail, getLiveDataCallback?:((realData:WSInfo) => void),dataFreshNessInMinutes:number=15):Promise<WSInfo>{
        this.checkGlobalCacheKillswitch(webAbsoluteUrl);
        //load from cache...
        const cachedInfo=await WSInfoCache.tryLoadFromCache(webAbsoluteUrl);
        if(cachedInfo){
            const loadWebInfo=WSInfoDetail.WebInfo === (wsInfoDetail & WSInfoDetail.WebInfo);
            const loadCN365Info=WSInfoDetail.CN365Info === (wsInfoDetail & WSInfoDetail.CN365Info);
            const loadSiteInfo=WSInfoDetail.SiteInfo === (wsInfoDetail & WSInfoDetail.SiteInfo);
            const loadGroupInfo=WSInfoDetail.GroupInfo === (wsInfoDetail & WSInfoDetail.GroupInfo);
            let dataMissing=false;
            if(loadWebInfo&&!cachedInfo.WSInfo.WebInfo){
                dataMissing=true;
            }
            if(loadCN365Info&&!cachedInfo.WSInfo.CN365Info){
                dataMissing=true;
            }
            if(loadSiteInfo&&!cachedInfo.WSInfo.SiteInfo){
                dataMissing=true;
            }
            if(loadGroupInfo&&!cachedInfo.WSInfo.GroupInfo){
                dataMissing=true;
            }
            // if(loadWebInfo&&(!cachedInfo.WSInfo.WebInfo ||(now-cachedInfo.WebInfoTimeStamp)>dataFreshNessInMinutesInSeconds)){
            //     dataMissing=true;
            // }
            // if(loadCN365Info&&(!cachedInfo.WSInfo.CN365Info||(now-cachedInfo.CN365InfoTimeStamp)>dataFreshNessInMinutesInSeconds)){
            //     dataMissing=true;
            // }
            // if(loadSiteInfo&&(!cachedInfo.WSInfo.SiteInfo||(now-cachedInfo.SiteInfoTimeStamp)>dataFreshNessInMinutesInSeconds)){
            //     dataMissing=true;
            // }
            // if(loadGroupInfo&&(!cachedInfo.WSInfo.GroupInfo||(now-cachedInfo.GroupInfoTimeStamp)>dataFreshNessInMinutesInSeconds)){
            //     dataMissing=true;
            // }
            if(dataMissing){
                const info=await this.loadData(webAbsoluteUrl, wsInfoDetail);
                if(info.WSInfo.WebInfo){
                    cachedInfo.WSInfo.WebInfo=info.WSInfo.WebInfo;  
                }
                if(info.WSInfo.CN365Info){
                    cachedInfo.WSInfo.CN365Info=info.WSInfo.CN365Info;  
                }
                if(info.WSInfo.SiteInfo){
                    cachedInfo.WSInfo.SiteInfo=info.WSInfo.SiteInfo;  
                }
                if(info.WSInfo.GroupInfo){
                    cachedInfo.WSInfo.GroupInfo=info.WSInfo.GroupInfo;  
                }
                await WSInfoCache.storeInCache(webAbsoluteUrl,cachedInfo);
            }
            else{
                if(getLiveDataCallback){
                    const now=new Date().getTime();
                    const dataFreshNessInMinutesInSeconds= dataFreshNessInMinutes*60*1000;
                    let wsInfoDatailTemp:WSInfoDetail=undefined;
                    if(loadWebInfo&&(now-cachedInfo.WebInfoTimeStamp)>dataFreshNessInMinutesInSeconds){
                        if(wsInfoDatailTemp){
                            wsInfoDatailTemp|=WSInfoDetail.WebInfo;
                        }
                        else{
                            wsInfoDatailTemp=WSInfoDetail.WebInfo;
                        }
                    }
                    if(loadCN365Info&&(now-cachedInfo.CN365InfoTimeStamp)>dataFreshNessInMinutesInSeconds){
                        if(wsInfoDatailTemp){
                            wsInfoDatailTemp|=WSInfoDetail.CN365Info;
                        }
                        else{
                            wsInfoDatailTemp=WSInfoDetail.CN365Info;
                        }
                    }
                    if(loadSiteInfo&&(now-cachedInfo.SiteInfoTimeStamp)>dataFreshNessInMinutesInSeconds){
                        if(wsInfoDatailTemp){
                            wsInfoDatailTemp|=WSInfoDetail.SiteInfo;
                        }
                        else{
                            wsInfoDatailTemp=WSInfoDetail.SiteInfo;
                        }
                    }
                    if(loadGroupInfo&&(now-cachedInfo.GroupInfoTimeStamp)>dataFreshNessInMinutesInSeconds){
                        if(wsInfoDatailTemp){
                            wsInfoDatailTemp|=WSInfoDetail.GroupInfo;
                        }
                        else{
                            wsInfoDatailTemp=WSInfoDetail.GroupInfo;
                        }
                    } 
                    if(wsInfoDatailTemp)                   
                    this.loadRealData(cachedInfo, webAbsoluteUrl, wsInfoDatailTemp, getLiveDataCallback);
                }
            }
            return cachedInfo.WSInfo;
        }
        else{
            const info=await this.loadData(webAbsoluteUrl, wsInfoDetail);
            await WSInfoCache.storeInCache(webAbsoluteUrl,info);
            return info.WSInfo;
        }
        
        
        
     }
     private async loadRealData(cachedInfo:WSInfoCacheItem, webAbsoluteUrl:string, wsInfoDetail:WSInfoDetail,getLiveDataCallback?:((realData:WSInfo) => void)):Promise<void>{
        //load real data
        const info=await this.loadData(webAbsoluteUrl, wsInfoDetail);
        if(cachedInfo.WSInfo.WebInfo&&!info.WSInfo.WebInfo){
            info.WSInfo.WebInfo=cachedInfo.WSInfo.WebInfo;
            info.WebInfoTimeStamp=new Date().getTime();
        }
        if(cachedInfo.WSInfo.CN365Info&&!info.WSInfo.CN365Info){
            info.WSInfo.CN365Info=cachedInfo.WSInfo.CN365Info;
            info.CN365InfoTimeStamp=new Date().getTime();
        }
        if(cachedInfo.WSInfo.SiteInfo&&!info.WSInfo.SiteInfo){
            info.WSInfo.SiteInfo=cachedInfo.WSInfo.SiteInfo;
            info.SiteInfoTimeStamp=new Date().getTime();
        }
        if(cachedInfo.WSInfo.GroupInfo&&!info.WSInfo.GroupInfo){
            info.WSInfo.GroupInfo=cachedInfo.WSInfo.GroupInfo;
            info.GroupInfoTimeStamp=new Date().getTime();
        }
        await WSInfoCache.storeInCache(webAbsoluteUrl,info);
                
        if(getLiveDataCallback&&!isEqual(cachedInfo.WSInfo,info.WSInfo))
        getLiveDataCallback(info.WSInfo);
     }

     private async loadData(webAbsoluteUrl:string, wsInfoDetail:WSInfoDetail): Promise<WSInfoCacheItem>{
        const loadWebInfo=WSInfoDetail.WebInfo === (wsInfoDetail & WSInfoDetail.WebInfo);

        let selectString="";
        
        if(loadWebInfo){
            selectString="Title,Id,SiteLogoUrl,ThemeData,IsMultilingual,Description,SupportedUILanguageIds,WebTemplate,Language,AllProperties&$expand=AllProperties";
        }

        let resp;

        const allPromises=new Array<Promise<any>>();

        const apiBaseUrl = `${webAbsoluteUrl}/_api`;

        const accessToken = await GetSharePointAccessToken();

        const requestHeaders: AxiosHeaders = new AxiosHeaders();
        
        requestHeaders.set('Authorization', `Bearer ${accessToken}`);
        requestHeaders.set('odata-version', '3.0');
        requestHeaders.set('Accept', 'application/json;odata=verbose');
        requestHeaders.set('Content-type', 'application/json;odata=verbose');

        if(loadWebInfo){
            const promise0 = axios.get(`${apiBaseUrl}/web?$select=`+selectString, { headers: requestHeaders });
            allPromises.push(promise0);
        }

        const responses=await Promise.all(allPromises);
        
        if(loadWebInfo){
            resp= await responses[0].data;
        }

        const InfoCacheItem:WSInfoCacheItem={
            WebUrl:webAbsoluteUrl,
            CN365InfoTimeStamp:new Date().getTime(),
            WebInfoTimeStamp:new Date().getTime(),
            GroupInfoTimeStamp:new Date().getTime(),
            SiteInfoTimeStamp:new Date().getTime(),
            WSInfo:{}
        };

        if(loadWebInfo){
            const color = this.getColor(resp.d.ThemeData);
            const acronym = this.getAcronym(resp.d.Title);
            const prefix=this.getPrefix(webAbsoluteUrl);

            try{
                const webInfo:WSInfoWebDetails={
                    WebId:resp.d.Id,
                    Title:resp.d.Title?resp.d.Title:"",
                    Description:resp.d.Description?resp.d.Description:"",
                    SiteLogoUrl:resp.d.SiteLogoUrl?resp.d.SiteLogoUrl:"",
                    Color:color,
                    Acronym:acronym,
                    Prefix:prefix,
                    WebTemplate:resp.d.WebTemplate?resp.d.WebTemplate:"",
                    IsMultilingual:resp.d.IsMultilingual,
                    SupportedUILanguageIds:new Array<number>(),
                    Language:resp.d.Language?resp.d.Language:"",
                };
                if(resp.d.SupportedUILanguageIds){
                    resp.d.SupportedUILanguageIds.results.forEach((lang)=>{
                        webInfo.SupportedUILanguageIds.push(lang);
                    });
                }
                InfoCacheItem.WSInfo.WebInfo=webInfo;
                InfoCacheItem.WebInfoTimeStamp=new Date().getTime();
            }catch(error){
                console.log("error getting web info");
                console.log(error);
            }            
        }

        return InfoCacheItem;
     }

     private getColor(themeData: any): string {
		const themeObject = JSON.parse(themeData);
		const colorObject = themeObject.Palette.Colors.ContentAccent1.DefaultColor as IColorObject;
		const calc = colorObject.A / 255;
		const a = parseFloat(calc.toString()).toFixed(1);
		const color = "rgba(" + colorObject.R + "," + colorObject.G + "," + colorObject.B + "," + a + ")";
		return color;
	}
    private getPrefix(webUrl: string): string {
		let prefix: string = "";
		const tempSiteIdentifier: string = webUrl.split("/")[4];
		if (tempSiteIdentifier && tempSiteIdentifier.indexOf("-") !== -1) {
			prefix = tempSiteIdentifier.substring(0, tempSiteIdentifier.indexOf("-"));

            if (prefix.length > 2) {
                prefix = prefix.substring(0, 2);
            }
			
            return prefix.toUpperCase();
		}
        else{
            return "";
        }	
		
	}
    

    private getAcronym(webTitle: string): string {
		let prefix: string = "";
			const siteTitlePrefix = webTitle.match(/\b(\w)/g);
			if (siteTitlePrefix) {
				if (siteTitlePrefix.length > 1) {
					prefix = siteTitlePrefix[0] + siteTitlePrefix[1];
				}
				else {
					prefix = siteTitlePrefix[0];
				}
			}	

		if (prefix.length > 2) {
			prefix = prefix.substring(0, 2);
		}
		return prefix.toUpperCase();
	}


    }

