import * as React from "react";
import { Theme as ThemeV9 } from '@fluentui/react-components';
import { app } from "@microsoft/teams-js";

export interface IRTCContext {
    tenantId: string;
    siteUrl: string;
    sitePath: string;
    siteId: string;
    groupId: string;
    upn: string;
    userDisplayName?: string;
    teamsContext: app.Context;
    componentId: string;
    themeV9: ThemeV9;
    themeName?: string;
    componentName: string;
    localization: IRTCresources;
}
const RTCContext = React.createContext<IRTCContext>({
    tenantId: "",
    siteUrl: "",
    sitePath: "",
    siteId: '',
    groupId: "",
    upn: "",
    userDisplayName: "",
    teamsContext: undefined,
    componentId: "",
    themeV9: undefined,
    themeName : "",
    componentName: "",
    localization: undefined
});

export default RTCContext;