import { MessageCommentItem } from "../../interfaces/IMessageItem";
import moment from "moment";
import axios, { AxiosHeaders } from "axios";
import { GetSharePointAccessToken } from "../HttpClientService/HttpClientService";

export default class CommentsService {
    private static async initializeCommentsList(siteUrl: string):Promise<void> { 
        const requestCreateCommentsList = {
            __metadata: {
              type: "SP.List"
            },
            AllowContentTypes: true,
            BaseTemplate: 100,
            ContentTypesEnabled: false,
            Description: "",
            Title: "RtCComments",
            OnQuickLaunch: false
        };   
        
        const accessToken = await GetSharePointAccessToken();

        const requestHeaders: AxiosHeaders = new AxiosHeaders();
        
        requestHeaders.set('Authorization', `Bearer ${accessToken}`);
        requestHeaders.set('odata-version', '3.0');
        requestHeaders.set('Accept', 'application/json;odata=verbose');
        requestHeaders.set('Content-type', 'application/json;odata=verbose');

        const respCreateViewsList = await axios.post(`${siteUrl}/_api/web/lists`, requestCreateCommentsList, { headers: requestHeaders });
    
        if(respCreateViewsList.status >= 200 && respCreateViewsList.status <= 300){
            //Add the comment field to the list
            const bodyCommentField = {
                __metadata: {
                    type: "SP.FieldMultiLineText"
                },        
                Title: 'Comment',
                FieldTypeKind: 3,
                StaticName: 'Comment',
                Required: false,
                EnforceUniqueValues: false,
                RichText: false,
                NumberOfLines: 20
            };

            const fieldHeaders: AxiosHeaders = new AxiosHeaders();

            fieldHeaders.set('Authorization', `Bearer ${accessToken}`);
            fieldHeaders.set('odata-version', '3.0');
            fieldHeaders.set('Accept', 'application/json;odata=verbose');
            fieldHeaders.set('Content-type', 'application/json;odata=verbose');
            fieldHeaders.set('IF-MATCH', '*');

            await axios.post(`${siteUrl}/_api/web/lists/getByTitle('RtCComments')/fields`, bodyCommentField, { headers: fieldHeaders });

            await axios.post(`${siteUrl}/_api/web/lists/getByTitle('RtCComments')/Views/GetByTitle('All%20Items')/ViewFields/addViewField('Comment')`, undefined, { headers: requestHeaders });
            await axios.post(`${siteUrl}/_api/web/lists/getByTitle('RtCComments')/Views/GetByTitle('All%20Items')/ViewFields/addViewField('Author')`, undefined, { headers: requestHeaders });
            await axios.post(`${siteUrl}/_api/web/lists/getByTitle('RtCComments')/Views/GetByTitle('All%20Items')/ViewFields/addViewField('Created')`, undefined, { headers: requestHeaders });
        }
    }

    public static async getCommentsForMessage(messageId: string, siteUrl: string, teamSitePath: string): Promise<MessageCommentItem[]> {
        const results: Array<MessageCommentItem> = new Array<MessageCommentItem>();

        const listUrl = `${teamSitePath}/Lists/RtCComments`;

        const restUrl = `${siteUrl}/_api/web/GetList(@listUrl)/RenderListDataAsStream?@listUrl=%27${encodeURIComponent(listUrl)}%27`;

        const requestBody = {
            parameters: {
                RenderOptions: 2,
                DatesInUtc: true,
                ViewXml: 
                `<View>
                    <Query>
                        <Where>
                            <Eq>
                                <FieldRef Name="Title" />
                                <Value Type="Text">${messageId.toUpperCase()}</Value>
                            </Eq>
                        </Where>
                        <OrderBy><FieldRef Name="Created" Ascending="False" /></OrderBy>
                    </Query>
                    <ViewFields>
                        <FieldRef Name="ID"/>
                        <FieldRef Name="Title"/>
                        <FieldRef Name="Comment"/>
                        <FieldRef Name="Created"/>
                        <FieldRef Name="Author"/>
                    </ViewFields>
                    <RowLimit>5000</RowLimit>
                </View>`
            }
        };

        const accessToken = await GetSharePointAccessToken();

        const requestHeaders: AxiosHeaders = new AxiosHeaders();
        
        requestHeaders.set('Authorization', `Bearer ${accessToken}`);
        requestHeaders.set('odata-version', '3.0');

        try{
            const response = await axios.post(restUrl, requestBody, { headers: requestHeaders });

            if(response.status >= 200 && response.status <= 300){
                const responseJSON = response.data;
    
                if(responseJSON && responseJSON.Row.length){
                    for(const item of responseJSON.Row){
                        const commentItem: MessageCommentItem = {
                            commentDateCreated: moment(item.Created).format("DD.MM.YYYY"),
                            commentCreatedBy: item.Author[0].title,
                            commentContent: item.Comment
                        };
                
                        results.push(commentItem);
                    }
                }            
            }else{
                await this.initializeCommentsList(siteUrl);
            }
        }catch{
            await this.initializeCommentsList(siteUrl);
        }        

        return results;
    }

    public static async saveCommentsForMessage(messageId: string, messageContent: string, siteUrl: string, teamSitePath: string): Promise<void> {
        try{
            const listUrl = `${teamSitePath}/Lists/RtCComments`;

            const restUrl: string = `${siteUrl}/_api/web/GetList(@listUrl)/items?@listUrl=%27${encodeURIComponent(listUrl)}%27`;
    
            const requestBody = {
                __metadata: {
                   type:`SP.Data.RtCCommentsListItem`
                },
                Title: messageId.toUpperCase(),
                Comment: messageContent
            };
      
            const accessToken = await GetSharePointAccessToken();

            const requestHeaders: AxiosHeaders = new AxiosHeaders();
            
            requestHeaders.set('Authorization', `Bearer ${accessToken}`);
            requestHeaders.set('odata-version', '3.0');
            requestHeaders.set('Accept', 'application/json;odata=verbose');
            requestHeaders.set('Content-type', 'application/json;odata=verbose');

            const response = await axios.post(restUrl, requestBody, { headers: requestHeaders });

            if(response.status >= 400){
                const errorMessage = await response.data;

                console.error(errorMessage);
            }
        }catch(ex){
            console.error(ex);
        }        
    }
}