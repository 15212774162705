import { ViewItem } from "../../interfaces/IViewItem";
import axios, { AxiosHeaders } from 'axios';
import { GetSharePointAccessToken } from "../HttpClientService/HttpClientService";

export default class ViewsService {
    private static async initializeViewsList(siteUrl: string):Promise<void> { 
        const requestCreateViewsList = {
            __metadata: {
              type: "SP.List"
            },
            AllowContentTypes: true,
            BaseTemplate: 100,
            ContentTypesEnabled: false,
            Description: "",
            Title: "RtCViews",
            OnQuickLaunch: false
        };

        const accessToken = await GetSharePointAccessToken();

        const requestHeaders: AxiosHeaders = new AxiosHeaders();
        
        requestHeaders.set('Authorization', `Bearer ${accessToken}`);
        requestHeaders.set('odata-version', '3.0');
        requestHeaders.set('Accept', 'application/json;odata=verbose');
        requestHeaders.set('Content-type', 'application/json;odata=verbose');

        const respCreateViewsList = await axios.post(`${siteUrl}/_api/web/lists`, requestCreateViewsList, { headers: requestHeaders });

        if(respCreateViewsList.status >= 200 && respCreateViewsList.status <= 300){
            //Add the comment field to the list
            const bodyConfigurationField = {
                __metadata: {
                    type: "SP.FieldMultiLineText"
                },        
                Title: 'Configuration',
                FieldTypeKind: 3,
                StaticName: 'Configuration',
                Required: false,
                EnforceUniqueValues: false,
                RichText: false,
                NumberOfLines: 20
            };

            const fieldHeaders: AxiosHeaders = new AxiosHeaders();
    
            fieldHeaders.set('Authorization', `Bearer ${accessToken}`);
            fieldHeaders.set('odata-version', '3.0');
            fieldHeaders.set('Accept', 'application/json;odata=verbose');
            fieldHeaders.set('Content-type', 'application/json;odata=verbose');
            fieldHeaders.set('IF-MATCH', '*');
        
            await axios.post(`${siteUrl}/_api/web/lists/getByTitle('RtCViews')/fields`, bodyConfigurationField, { headers: fieldHeaders });

            await axios.post(`${siteUrl}/_api/web/lists/getByTitle('RtCViews')/Views/GetByTitle('All%20Items')/ViewFields/addViewField('Configuration')`, undefined, { headers: requestHeaders });
            await axios.post(`${siteUrl}/_api/web/lists/getByTitle('RtCViews')/Views/GetByTitle('All%20Items')/ViewFields/addViewField('Author')`, undefined, { headers: requestHeaders });
        }
    }

    public static async getViewsForUser(siteUrl: string, teamSitePath: string, userDisplayName: string): Promise<ViewItem[]> {
        const results: Array<ViewItem> = new Array<ViewItem>();

        const listUrl = `${teamSitePath}/Lists/RtCViews`;
        const restUrl = `${siteUrl}/_api/web/GetList(@listUrl)/RenderListDataAsStream?@listUrl=%27${encodeURIComponent(listUrl)}%27`;

        const requestBody = {
            parameters: {
                RenderOptions: 2,
                DatesInUtc: true,
                ViewXml: 
                `<View>
                    <Query>
                        <OrderBy><FieldRef Name="Title" Ascending="True" /></OrderBy>
                    </Query>
                    <ViewFields>
                        <FieldRef Name="ID"/>
                        <FieldRef Name="Configuration"/>
                        <FieldRef Name="Author"/>
                    </ViewFields>
                    <RowLimit>5000</RowLimit>
                </View>`
            }
        };

        const accessToken = await GetSharePointAccessToken();

        const requestHeaders: AxiosHeaders = new AxiosHeaders();
        
        requestHeaders.set('Authorization', `Bearer ${accessToken}`);
        requestHeaders.set('odata-version', '3.0');

        try{
            const response = await axios.post(restUrl, requestBody, { headers: requestHeaders });

            if(response.status >= 200 && response.status <= 300){
                const responseJSON = await response.data;
    
                if(responseJSON && responseJSON.Row.length){
                    for(const item of responseJSON.Row){
                        if(userDisplayName === item.Author[0].title){
                            const viewItem: ViewItem = JSON.parse(item.Configuration);
                            viewItem.viewId = item.ID;
    
                            results.push(viewItem);
                        }                    
                    }
                }            
            }else{
                await this.initializeViewsList(siteUrl);
            }
        }catch{
            await this.initializeViewsList(siteUrl);
        }        

        return results;
    }

    public static async saveView(viewItem: ViewItem, siteUrl: string, teamSitePath: string): Promise<void> {
        try{
            const listUrl = `${teamSitePath}/Lists/RtCViews`;

            const restUrl: string = `${siteUrl}/_api/web/GetList(@listUrl)/items?@listUrl=%27${encodeURIComponent(listUrl)}%27`;
    
            const requestBody = {
                __metadata: {
                   type:`SP.Data.RtCViewsListItem`
                },
                Title: viewItem.viewName,
                Configuration: JSON.stringify(viewItem)
            };

            const accessToken = await GetSharePointAccessToken();

            const requestHeaders: AxiosHeaders = new AxiosHeaders();
            
            requestHeaders.set('Authorization', `Bearer ${accessToken}`);
            requestHeaders.set('odata-version', '3.0');
            requestHeaders.set('Accept', 'application/json;odata=verbose');
            requestHeaders.set('Content-type', 'application/json;odata=verbose');
  
            const result = await axios.post(restUrl, requestBody, { headers: requestHeaders });

            if(result.status >= 400){
                const errorMessage = await result.data;

                console.error(errorMessage);
            }
        }catch(ex){
            console.error(ex);
        }        
    }

    public static async updateView(viewItem: ViewItem, siteUrl: string, teamSitePath: string): Promise<void> {
        try{
            const listUrl = `${teamSitePath}/Lists/RtCViews`;

            const restUrl: string = `${siteUrl}/_api/web/GetList(@listUrl)/items?@listUrl=%27${encodeURIComponent(listUrl)}%27`;
    
            const requestBody = {
                __metadata: {
                   type:`SP.Data.RtCViewsListItem`
                },
                Title: viewItem.viewName,
                Configuration: JSON.stringify(viewItem)
            };

            const accessToken = await GetSharePointAccessToken();

            const requestHeaders: AxiosHeaders = new AxiosHeaders();
            
            requestHeaders.set('Authorization', `Bearer ${accessToken}`);
            requestHeaders.set('odata-version', '3.0');
            requestHeaders.set('If-Match', '*');
            requestHeaders.set('X-HTTP-Method', 'MERGE');
    
            const result = await axios.post(restUrl, requestBody, { headers: requestHeaders });

            if(result.status >= 400){
                const errorMessage = await result.data;

                console.error(errorMessage);
            }
        }catch(ex){
            console.error(ex);
        }        
    }

    public static async deleteView(viewItem: ViewItem, siteUrl: string, teamSitePath: string): Promise<void> {
        try{
            const listUrl = `${teamSitePath}/Lists/RtCViews`;

            const restUrl: string = `${siteUrl}/_api/web/GetList(@listUrl)/items(${viewItem.viewId})?@listUrl=%27${encodeURIComponent(listUrl)}%27`;
        
            const accessToken = await GetSharePointAccessToken();

            const requestHeaders: AxiosHeaders = new AxiosHeaders();
            
            requestHeaders.set('Authorization', `Bearer ${accessToken}`);
            requestHeaders.set('odata-version', '3.0');
            requestHeaders.set('Accept', 'application/json;odata=verbose');
            requestHeaders.set('Content-type', 'application/json');
            requestHeaders.set('If-Match', '*');
            requestHeaders.set('X-HTTP-Method', 'DELETE');
 
            const result = await axios.post(restUrl, undefined, { headers: requestHeaders });

            if(result.status >= 400){
                const errorMessage = await result.data;

                console.error(errorMessage);
            }
        }catch(ex){
            console.error(ex);
        }        
    }
}