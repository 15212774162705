  export enum PrincipalType {
    /**
     * Users
     */
    Users = 1,
    /**
     * Security Group
     */
    Security = 4,
    /**
     * SharePoint Group
     */
    SharePoint = 8
  }
  
  