import * as React from 'react';
import { MessageItem, MessageRoleAction } from "../interfaces/IMessageItem";
import { Badge } from '@fluentui/react-components';

export class ReactHelper{
    public static renderBadge(severity: string, localization: IRTCresources): JSX.Element {
        let badgeColor: "brand" |
        "danger" |
        "important" |
        "informative" |
        "severe" |
        "subtle" |
        "success" |
        "warning" = 'informative';
    
        let badgeText = localization.WebParts.Dashboard.ignore.toUpperCase();
    
        const action = severity as MessageRoleAction;
    
        switch(action){
            case MessageRoleAction.note:
                badgeText = localization.WebParts.Dashboard.note.toUpperCase();
                badgeColor = "success";
                break;
            case MessageRoleAction.consider:
                badgeText = localization.WebParts.Dashboard.consider.toUpperCase();
                badgeColor = "severe";
                break;
            case MessageRoleAction.act:
                badgeText = localization.WebParts.Dashboard.act.toUpperCase();
                badgeColor = "danger";
                break;
        }
    
        return (<Badge appearance="tint" color={badgeColor} style={{width: 75, height: 22}}>{badgeText}</Badge>);
    }

    public static renderServicesString(message: MessageItem): string{
        return (message.messageServices.join(' | '));
    }
}