import { ExportDestination, IExportTaskDestination } from "../../interfaces/IExportTaskDestination";
import moment from "moment";
import { GetGraphAccessToken } from "../HttpClientService/HttpClientService";
import HttpHeaderHelper from "../../helpers/HttpHeadersHelper";
import axios from "axios";

export default class ToDoService {
    public static async getTodos(): Promise<IExportTaskDestination[]> {
        const todos: IExportTaskDestination[] = [];

        try{
            const accessToken = await GetGraphAccessToken();

            const reqHeaders = HttpHeaderHelper.getHeaders(undefined, false, false, accessToken);

            const todosJson = await axios.get(`https://graph.microsoft.com/v1.0/me/todo/lists`, { headers: reqHeaders });

          if(todosJson.data.value && todosJson.data.value.length > 0){
            todosJson.data.value.forEach(element => {
                todos.push({ Id: element.id, Title: element.displayName, Destination: ExportDestination.Todo });
            });
          }
        }catch(error){
            console.error(error);
        }

        return todos;
    }

    public static async saveTodo(taskTitle: string, taskDescription: string, taskDueDate: Date, taskList: string): Promise<boolean>{
        if(!taskTitle || taskTitle.length === 0 || !taskDescription || taskDescription.length === 0 || !taskList || taskList.length === 0){
            return false;
        }

        let result = false;

        try{
            const body: any = {}; // eslint-disable-line @typescript-eslint/no-explicit-any

            body.title = taskTitle;

            body.body = {
                contentType: "html",
                content: taskDescription
            };

            if(taskDueDate){
                body.dueDateTime = {
                    dateTime:  moment(taskDueDate).add(3, 'hours').toISOString(),
                    timeZone: "Europe/Berlin"
                }
            }

            const accessToken = await GetGraphAccessToken();

            const reqHeaders = HttpHeaderHelper.getHeaders(undefined, false, false, accessToken);

            const responsePostMessage = await axios.post(`https://graph.microsoft.com/v1.0/me/todo/lists/${taskList}/tasks`, body, { headers: reqHeaders });

            if(responsePostMessage && responsePostMessage.data.id){
                result = true;
            }
        }catch(error){
            console.error(error);
        }

        return result;
    }
}