/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import {
  FluentProvider,
  teamsLightTheme,
  teamsDarkTheme,
  teamsHighContrastTheme,
  Label,
  Input,
  RadioGroup,
  Radio,
  Spinner,
  Tooltip,
} from "@fluentui/react-components";
import { app, pages } from "@microsoft/teams-js";
import { Info16Regular } from "@fluentui/react-icons";
import LocalizationService from "../common/services/LocalizationService/LocalizationService";

export interface IConfigProps {}

export const Configuration: React.FunctionComponent<IConfigProps> = (props) => {
  const [subscriptionKey, setSubscriptionKey] = useState<string>('');
  const [sharedMode, setSharedMode] = useState<boolean>(false);
  const [freeTrialMode, setFreeTrialMode] = useState<boolean>(false);
  const [themeName, setThemeName] = useState<string>('default');
  const [localization, setLocalization] = useState<IRTCresources>(undefined);
  const [alreadyMounted, setAlreadyMounted] = useState<boolean>(false);

  const componentDidMount = async () => {
    await app.initialize();

    const teamsContext = await app.getContext();

    setThemeName(teamsContext.app.theme);

    const configValue = await pages.getConfig();

    const loc = await LocalizationService.loadLocale(teamsContext.app.locale);
    setLocalization(loc);

    if(configValue.entityId){
      const entityId = configValue.entityId;
      const entityIdParts = entityId.split(':');

      if(entityIdParts.length === 2){
        setSubscriptionKey(entityIdParts[0]);
        setSharedMode(entityIdParts[1] === 'true');
      }
    }    
  };

  const save = async () => {
    if(!alreadyMounted){
      await app.initialize();
    }

    if(subscriptionKey && subscriptionKey.length > 0) {
      pages.config.setValidityState(true);

      pages.config.registerOnSaveHandler((saveEvent) => {
        const baseUrl = `https://${window.location.hostname}:${window.location.port}`;

        const configPromise = pages.config.setConfig({
            websiteUrl: `${baseUrl}/index.html#/tab`,
            contentUrl: `${baseUrl}/index.html#/tab`,
            entityId: `${subscriptionKey}:${sharedMode}`
        });
        configPromise.then((result) => {saveEvent.notifySuccess()}).catch((error) => {saveEvent.notifyFailure("failure message")});
      });
    }else{
      pages.config.setValidityState(false);
    }
  };

  useEffect(() => {
    if(localization !== undefined){
      setAlreadyMounted(true);
    }
  }, [localization]);

  useEffect(() => {
    save();
  }, [subscriptionKey, sharedMode]);

  useEffect(() => {    
    componentDidMount();
  }, []);
  
  return (
    <FluentProvider
      theme={
        themeName === "dark"
          ? teamsDarkTheme
          : themeName === "contrast"
          ? teamsHighContrastTheme
          : {
              ...teamsLightTheme,
              colorNeutralBackground3: "#eeeeee",
            }
      }
    >
      {!alreadyMounted && <Spinner />}
      {alreadyMounted && 
      <>
        <Label style={{fontSize: 20, fontWeight: 600}}>{localization.CommonStrings.PropertyPaneGroupSettings}</Label>
        <br /><br />
        <Label>{localization.PropertyPaneStrings.header}</Label>
        <br /><br />
        <Label style={{fontWeight: 600}}>{localization.PropertyPaneStrings.subscriptionKey}</Label>
        <br />
        <Input type='text' style={{width: '80%', marginTop: 5}} value={subscriptionKey} onChange={(ev, data) => setSubscriptionKey(data.value)} />
        <br /><br />
        <Label style={{fontWeight: 600}}>{localization.PropertyPaneStrings.modeSelector}</Label>
        <br />
        <RadioGroup value={sharedMode ? 'groupSpecific' : 'userSpecific'} onChange={(_, data) => setSharedMode(data.value !== 'userSpecific')}>
          <Radio value="userSpecific" label={localization.PropertyPaneStrings.individualMode} />
          <div><Radio value="groupSpecific" label={localization.PropertyPaneStrings.groupMode} /><Tooltip withArrow positioning='after' content={localization.WebParts.Tooltips.modeSelector} relationship="label"><Info16Regular /></Tooltip></div>
        </RadioGroup>
        <br /><br />
        <Label>{localization.PropertyPaneStrings.freeTrialDescription}</Label>
        <br />
        <Label>{localization.PropertyPaneStrings.disclaimer}</Label>
        <br /><br /><br />
        <Label>{localization.PropertyPaneStrings.contactUs}</Label>
      </>}
    </FluentProvider>
  );
}