export interface ILicenseStatus{
    IsValid: boolean;
    Type?: LicenseType;
    Message?: string;
}

export enum LicenseType {
    Basic = "0", 
    Standard = "1", 
    Compliance = "2"
}