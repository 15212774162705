export class UrlUtilities{
    /** 
    * Trims slash at the end of URL if needed 
    * @param url URL 
    */ 
    public static removeEndSlash(url:string):string{ 
     if (url.lastIndexOf('/') === url.length - 1){ 
      return url.slice(0, -1); 
     }
     else{
      return url; 
     }
    }           

    /**
     * Validate if URL is a valid absolute secure (https) url
     */
    public static isValidAbsoluteUrl(url:string):boolean{        
        const format = new RegExp(/((\w+:\/\/)[-a-zA-Z0-9:@;?&=/%+.*!'(),$_{}^~[\]`#|]+)/);        
        return !format.test(url);
    }

    public static isValidSharePointUrl(url:string):boolean{
        const format = new RegExp(/(https)(:)(\/)(\/)(([\da-z.-]+))(\.)(sharepoint)(\.)(com)/);        
        return format.test(url);
    }

    public static isValidPartialSharePointUrl(url:string):boolean{
        const format = new RegExp(/.+(sharepoint)(\.)(com).+/);
        return format.test(url);
  
    }

    public static toFriendlyUrl = (name: string):string => {
        return name.toString()               // Convert to string
            .normalize('NFD')               // Change diacritics
            .replace(/[\u0300-\u036f]/g,'') // Remove illegal characters
            .replace(/\s+/g,'')             // Remove whitespaces
            .toLowerCase()                  // Change to lowercase
            .replace(/&/g,'-and-')          // Replace ampersand
            .replace(/[^a-z0-9-]/g,'')     // Remove anything that is not a letter, number or dash
            .replace(/-+/g,'-')             // Remove duplicate dashes
            .replace(/^-*/,'')              // Remove starting dashes
            .replace(/-*$/,'');             // Remove trailing dashes
    }

    public static getUrlParameter(parameterName: string, url?: string):string {
        if (!url) {
            url = window.location.href;
        }
        parameterName = parameterName.replace(/[[\]]/g, '\\$&');

        const regex = new RegExp('[?&]' + parameterName + '(=([^&#]*)|&|#|$)');
        const results = regex.exec(url);
        if (!results){
           return null; 
        } 
        if (!results[2]) {
            return '';
        } 
        return decodeURIComponent(results[2].replace(/\+/g, ' '));
    }

    public static isValidURL(url: string): boolean {
        const urlPattern = /^(https?):\/\/[^\s/$.?#]+\.[^\s]*$/i;
        return urlPattern.test(url);
    }
}
