import * as React from 'react';
import { NormalPeoplePicker, IBasePickerSuggestionsProps, IBasePickerStyles } from "@fluentui/react/lib/Pickers";
import { Label } from "@fluentui/react/lib/Label";
import { IPersonaProps, PersonaPresence, PersonaInitialsColor } from "@fluentui/react/lib/Persona";
import SPPeopleSearchService, { IGroupOrPerson } from './SPPeopleSearchService';
import { PrincipalType } from './PrincipalType';
import { IPeoplePickerProperties } from './IPeoplePickerProperties';
import styles from './PeoplePicker.module.scss';
import { isEqual } from 'lodash';
import RTCContext from '../RTCWrapper/RTCContext';
import { GetGraphAccessToken, GetSharePointAccessToken } from '../../common/services/HttpClientService/HttpClientService';
import HttpHeaderHelper from '../../common/helpers/HttpHeadersHelper';
import axios, { AxiosHeaders } from 'axios';

/**
 * Defines the state of the component
 */
export interface IPeoplePickerState {

  //resultsPeople?: Array<IGroupOrPerson>;
  //resultsPersonas?: Array<IPersonaProps>;
  errorMessage?: string;
  selectedPeople: Array<IGroupOrPerson>;
  isRequired: boolean;
}

/**
 * Renders the controls for PropertyFieldPeoplePicker component
 */
export default class PeoplePicker extends React.Component<IPeoplePickerProperties, IPeoplePickerState> {
  
  static contextType = RTCContext;
  context!: React.ContextType<typeof RTCContext>;

  private searchService: SPPeopleSearchService;
  private intialPersonas: Array<IPersonaProps> = new Array<IPersonaProps>();
  private resultsPeople: Array<IGroupOrPerson> = new Array<IGroupOrPerson>();
  private resultsPersonas: Array<IPersonaProps> = new Array<IPersonaProps>();
  private selectedPeople: Array<IGroupOrPerson> = new Array<IGroupOrPerson>();
  private selectedPersonas: Array<IPersonaProps> = new Array<IPersonaProps>();
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  private o365GroupMembers: Array<any> = new Array<any>();
  //private async: Async;
  //private delayedValidate: (value: IGroupOrPerson[]) => void;

  /**
   * Constructor method
   */
  constructor(props: IPeoplePickerProperties) {
    super(props);

    this.searchService = new SPPeopleSearchService();
    this.onSearchFieldChanged = this.onSearchFieldChanged.bind(this);
    this.onItemChanged = this.onItemChanged.bind(this);

    this.createInitialPersonas();

    //this.resultsPeople=this.props.initialData?this.props.initialData:new Array<IGroupOrPerson>();
    //this.selectedPeople=this.props.initialData?this.props.initialData:new Array<IGroupOrPerson>();
    this.state = {
      //resultsPeople: this.resultsPeople,
      selectedPeople: this.selectedPeople,
      //resultsPersonas: this.resultsPersonas,
      isRequired: this.props.isRequired,
      errorMessage: this.props.isRequired && this.selectedPeople.length === 0 ? this.context.localization.CommonStrings.RequiredField : ''
    };

    //this.async = new Async(this);
    this.validate = this.validate.bind(this);
    this.notifyAfterValidate = this.notifyAfterValidate.bind(this);
    //this.delayedValidate = this.async.debounce(this.validate, this.props.deferredValidationTime);


  }

  public static getDerivedStateFromProps(nextProps: Readonly<IPeoplePickerProperties>, prevState: IPeoplePickerState): Partial<IPeoplePickerState> {
    if (nextProps.isRequired !== prevState.isRequired) {
      const newState = { ...prevState };
      newState.errorMessage = nextProps.isRequired && prevState.selectedPeople.length === 0 ? 'Required' : '';
      newState.isRequired = nextProps.isRequired;
      return newState;
    }
    
    return null;
  }

  public getSnapshotBeforeUpdate(prevProps: Readonly<IPeoplePickerProperties>, prevState: Readonly<IPeoplePickerState>):boolean {
      if (!isEqual(prevProps.selectedItems, this.props.selectedItems)){
        return true;
      }
      return null;
  }

  public componentDidUpdate(prevProps: Readonly<IPeoplePickerProperties>, prevState: Readonly<IPeoplePickerState>, snapshot?: boolean): void {
      if (snapshot){
        const newState = { ...this.state };
        newState.errorMessage = this.state.isRequired && this.props.selectedItems.length === 0 ? this.context.localization.CommonStrings.RequiredField : '';
        this.setState(newState);
      }
  }

  /**
  * A search field change occured
  */
  private onSearchFieldChanged(searchText: string, currentSelected: IPersonaProps[]): Promise<IPersonaProps[]> | IPersonaProps[] {
    if (searchText.length > 2) {
      if (this.props.limitToCurrentO365Group || this.props.showM365Groups || (this.props.groupMembers && this.props.groupMembers.length > 0)) {

        if (this.o365GroupMembers.length === 0 && this.props.groupMembers && this.props.groupMembers.length > 0) {
          this.o365GroupMembers = this.props.groupMembers;
        }

        this.resultsPersonas = [];
        //Filter O365 group members by search text
        const filteredResults = this.o365GroupMembers.filter((element) => {
          if (this.props.showM365Groups) {
            //Fix for security groups with no email
            return (element.mail && element.mail.toLowerCase().indexOf(searchText.toLowerCase()) !== -1)
              || (element.email && element.email.toLowerCase().indexOf(searchText.toLowerCase()) !== -1)
              || element.displayName.toLowerCase().indexOf(searchText.toLowerCase()) !== -1;
          } else {
            return (element.mail && element.mail.toLowerCase().indexOf(searchText.toLowerCase()) !== -1)
              || (element.email && element.email.toLowerCase().indexOf(searchText.toLowerCase()) !== -1)
              || element.displayName.toLowerCase().indexOf(searchText.toLowerCase()) !== -1;
          }
        });
        filteredResults.forEach((element, index: number) => {
          let hideUser;
          if (this.props.hideUsers && this.props.hideUsers.length > 0) {
            hideUser = this.props.hideUsers.find(f => f === element.mail);
          }
          if (!hideUser) {
            let person: IGroupOrPerson;
            const email = element.mail ? element.mail : element.email;
            //Create IGroupOrPerson Object from graph user object
            if (this.props.showM365Groups || (this.props.groupMembers && this.props.groupMembers.length > 0)) {
              person = {
                objectId: element.id,
                email: email,
                fullName: element.displayName,
                login: `i:0#.f|membership|${email ? email : element.displayName}`,
                isExternal: false
              };
            } else {
              person = {
                objectId: element.id,
                email: email,
                fullName: element.displayName,
                login: `i:0#.f|membership|${element.userPrincipalName}`,
                isExternal: element.userPrincipalName.indexOf('EXT') > 0 ? true : false,
                jobTitle: element.jobTitle
              };
            }

            if (!this.props.excludeGuests || (this.props.excludeGuests && !person.isExternal)) {
              // Fill the results Array
              this.resultsPeople.push(person);
              // Transform the response in IPersonaProps object
              this.resultsPersonas.push(this.getPersonaFromPeople(person, index));
            }
          }
        });

        return this.resultsPersonas;

      }
      else {
        // Clear the suggestions list
        //this.setState({ resultsPeople: this.resultsPeople, resultsPersonas: this.resultsPersonas });
        // Request the search service
        const webUrl = this.props.remoteWebUrl ? this.props.remoteWebUrl : this.context.siteUrl;

        //REVIEW
        GetSharePointAccessToken().then((accessToken) => {
          const requestHeaders: AxiosHeaders = new AxiosHeaders();
            
          requestHeaders.set('Authorization', `Bearer ${accessToken}`);
          requestHeaders.set('Accept', 'application/json');
          requestHeaders.set('Content-type', 'application/json');

          const result = this.searchService.searchPeople(requestHeaders, webUrl, searchText, this.props.principalType, this.props.resolveEmailAdresses).then((response: IGroupOrPerson[]) => {
            //this.resultsPeople = [];
            this.resultsPersonas = [];
            // If allowDuplicate == false, so remove duplicates from results
            if (this.props.allowDuplicate === false) {
              response = this.removeDuplicates(response);
            }
            response.forEach((element: IGroupOrPerson, index: number) => {
              let hideUser;
              if (this.props.hideUsers && this.props.hideUsers.length > 0) {
                hideUser = this.props.hideUsers.find(f => f === element.email);
              }
              if (!hideUser) {
                if (!this.props.excludeGuests || (this.props.excludeGuests && !element.isExternal)) {
                  // Fill the results Array
                  this.resultsPeople.push(element);
                  // Transform the response in IPersonaProps object
                  this.resultsPersonas.push(this.getPersonaFromPeople(element, index));
                }
              }
            });
            // Refresh the component's state
            //this.setState({ resultsPeople: this.resultsPeople, resultsPersonas: this.resultsPersonas });
            return this.resultsPersonas;
          });
          return result;
        });        
      }
    }
    else {
      return [];
    }
  }

  /**
   * Remove the duplicates if property allowDuplicate equals false
   */
  private removeDuplicates(responsePeople: IGroupOrPerson[]): IGroupOrPerson[] {
    if (!this.selectedPeople || this.selectedPeople.length === 0) {
      return responsePeople;
    }

    const res: IGroupOrPerson[] = [];
    for (const element of responsePeople) {
      let found: boolean = false;

      for (let i: number = 0; i < this.selectedPeople.length; i++) {
        const responseItem: IGroupOrPerson = this.selectedPeople[i];
        if (responseItem.login === element.login &&
          responseItem.id === element.id) {
          found = true;
          break;
        }
      }

      if (found === false) {
        res.push(element);
      }
    }
    return res;
  }

  /**
   * Creates the collection of initial personas from initial IPropertyFieldGroupOrPerson collection
   */
  private createInitialPersonas(): void {
    if (!this.props.initialData || typeof (this.props.initialData) !== typeof Array<IGroupOrPerson>()) {
      return;
    }

    this.props.initialData.forEach((element: IGroupOrPerson, index: number) => {
      const persona: IPersonaProps = this.getPersonaFromPeople(element, index);
      this.intialPersonas.push(persona);
      this.selectedPersonas.push(persona);
      this.selectedPeople.push(element);
      this.resultsPeople.push(element);
    });
  }

  /**
   * Generates a IPersonaProps object from a IPropertyFieldGroupOrPerson object
   */
  private getPersonaFromPeople(element: IGroupOrPerson, index: number): IPersonaProps {
    return {
      primaryText: element.fullName,
      secondaryText: element.jobTitle,
      imageUrl: element.imageUrl,
      imageInitials: element.initials,
      presence: PersonaPresence.none,
      tertiaryText: element.login,
      initialsColor: this.getRandomInitialsColor(index)
    };
  }

  /**
   * Refreshes the web part properties
   */
  private refreshWebPartProperties(): void {
    //this.delayedValidate(this.selectedPeople);
    this.validate(this.selectedPeople);
  }

  /**
  * Validates the new custom field value
  */
  private validate(value: IGroupOrPerson[]): void {

    const newState = { ...this.state };
    if (!this.props.onGetErrorMessage) {
      this.notifyAfterValidate(value);
      newState.errorMessage = this.props.isRequired && value.length === 0 ? this.context.localization.CommonStrings.RequiredField : '';
    } else {
      const errResult: string = this.props.onGetErrorMessage(value || []);
      if (errResult) {
        if (errResult === '') {
          this.notifyAfterValidate(value);
        }
        newState.errorMessage = this.props.isRequired && value.length === 0 ? this.context.localization.CommonStrings.RequiredField : errResult;
      }
      else {
        this.notifyAfterValidate(value);
        newState.errorMessage = this.props.isRequired && value.length === 0 ? this.context.localization.CommonStrings.RequiredField : '';
      }
    }
    newState.selectedPeople = value;
    this.setState(newState);

  }

  /**
   * Notifies the parent Web Part of a property value change
   */
  private notifyAfterValidate(newValue: IGroupOrPerson[]): void {
    this.props.onChange(newValue);
  }

  /**
   * Called when the component will unmount
   */
  public componentWillUnmount(): void {
    //this.async.dispose();
  }

  public async componentDidMount(): Promise<void> {
    if (this.props.groupMembers && this.props.groupMembers.length > 0) {
      this.o365GroupMembers = this.props.groupMembers;
    } else {
      const accessToken = await GetGraphAccessToken();

      const reqHeaders = HttpHeaderHelper.getHeaders(undefined, false, false, accessToken);

      if (this.props.showM365Groups) {
        let membersResponse = await axios.get(`https://graph.microsoft.com/v1.0/groups?$filter=groupTypes/any(c:c eq 'Unified') or securityEnabled eq true&$select=id,displayName,mail,userPrincipalName,jobTitle`, { headers: reqHeaders });
        if (membersResponse && membersResponse.data.value) {
          this.o365GroupMembers = membersResponse.data.value;

          while (membersResponse['@odata.nextLink'] !== undefined && membersResponse['@odata.nextLink'] !== '') {
            membersResponse = await axios.get(membersResponse['@odata.nextLink'], { headers: reqHeaders });

            if (membersResponse && membersResponse.data.value) {
              this.o365GroupMembers.push(...membersResponse.data.value);
            }
          }
        }
      } else if (this.props.limitToCurrentO365Group || (this.props.groupId && this.props.groupId.length > 0)) {
        let groupId = '';

        if (this.props.groupId && this.props.groupId.length > 0) {
          groupId = this.props.groupId;
        } else if (this.context.groupId && this.context.groupId.length > 0) {
          groupId = this.context.groupId;
        }

        if (groupId.length > 0) {
          const membersResponse = await axios.get(`https://graph.microsoft.com/v1.0/groups/${groupId}/members?$select=id,displayName,mail,userPrincipalName,jobTitle`, { headers: reqHeaders });

          if (membersResponse && membersResponse.data.value) {
            this.o365GroupMembers = membersResponse.data.value;
          }
        }
      }
    }
  }

  /**
   * Find the index of the selected person
   * @param selectedItem
   */
  private _findIndex(selectedItem: IPersonaProps): number {
    for (let i = 0; i < this.resultsPersonas.length; i++) {
      const crntPersona = this.resultsPersonas[i];
      // Check if the imageUrl, primaryText, secondaryText are equal
      if (crntPersona.imageUrl === selectedItem.imageUrl &&
        crntPersona.primaryText === selectedItem.primaryText &&
        crntPersona.secondaryText === selectedItem.secondaryText) {
        return i;
      }
    }
    return -1;
  }



  private getPeopleFromPersona(element: IPersonaProps): IGroupOrPerson {

    //no exceptionhandling because we should never have a persona that is not in resultspeople
    const groupOrPerson = this.resultsPeople.filter(p => p.login === element.tertiaryText)[0];
    return groupOrPerson;
  }

  private getPeoplesFromPersonas(personas: IPersonaProps[]): IGroupOrPerson[] {
    const peoples = new Array<IGroupOrPerson>();
    personas.forEach((persona) => {
      peoples.push(this.getPeopleFromPersona(persona));
    });
    return peoples;
  }

  /**
   * Event raises when the user changed people from the PeoplePicker component
   */
  private onItemChanged(selectedItems: IPersonaProps[]): void {
    // if (selectedItems.length > 0) {
    //   if (selectedItems.length > this.selectedPersonas.length) {
    //     const index: number = this._findIndex(selectedItems[selectedItems.length - 1]);
    //     if (index > -1) {
    //       const people: IGroupOrPerson = this.resultsPeople[index];
    //       this.selectedPeople.push(people);
    //       this.selectedPersonas.push(this.resultsPersonas[index]);
    //     }
    //   } else {
    //     this.selectedPersonas.forEach((person, index2) => {
    //       const selectedItemIndex: number = selectedItems.indexOf(person);
    //       if (selectedItemIndex === -1) {
    //         this.selectedPersonas.splice(index2, 1);
    //         this.selectedPeople.splice(index2, 1);
    //       }
    //     });
    //   }
    // } else {
    //   this.selectedPersonas.splice(0, this.selectedPersonas.length);
    //   this.selectedPeople.splice(0, this.selectedPeople.length);
    // }
    this.selectedPersonas = selectedItems;
    this.selectedPeople = this.getPeoplesFromPersonas(selectedItems);

    this.refreshWebPartProperties();
  }

  /**
   * Generate a PersonaInitialsColor from the item position in the collection
   */
  private getRandomInitialsColor(index: number): PersonaInitialsColor {
    const num: number = index % 13;
    switch (num) {
      case 0: return PersonaInitialsColor.blue;
      case 1: return PersonaInitialsColor.darkBlue;
      case 2: return PersonaInitialsColor.teal;
      case 3: return PersonaInitialsColor.lightGreen;
      case 4: return PersonaInitialsColor.green;
      case 5: return PersonaInitialsColor.darkGreen;
      case 6: return PersonaInitialsColor.lightPink;
      case 7: return PersonaInitialsColor.pink;
      case 8: return PersonaInitialsColor.magenta;
      case 9: return PersonaInitialsColor.purple;
      case 10: return PersonaInitialsColor.black;
      case 11: return PersonaInitialsColor.orange;
      case 12: return PersonaInitialsColor.red;
      case 13: return PersonaInitialsColor.darkRed;
      default: return PersonaInitialsColor.blue;
    }
  }

  /**
   * Renders the PeoplePicker controls with Office UI  Fabric
   */
  public render(): JSX.Element {
    const suggestionProps: IBasePickerSuggestionsProps = {
      suggestionsHeaderText: "Suggested people",
      noResultsFoundText: "No result found",
      loadingText: "Loading results ...",
    };
    // Check which text have to be shown
    if (this.props.principalType && this.props.principalType.length > 0) {
      const userType = this.props.principalType.indexOf(PrincipalType.Users) !== -1;
      const groupType = this.props.principalType.indexOf(PrincipalType.SharePoint) !== -1 || this.props.principalType.indexOf(PrincipalType.Security) !== -1;

      // Check if both user and group are present
      if (userType && groupType) {
        suggestionProps.suggestionsHeaderText = 'Suggested people and groups';
      }

      // If only group is active
      if (!userType && groupType) {
        suggestionProps.suggestionsHeaderText = "Suggested groups";
      }
    }


    let myStyle: IBasePickerStyles = this.props.peoplePickerStyles ? this.props.peoplePickerStyles : null;
    try {
      //this.context.loggingService.logCustomerEvent("Test: " + this.context.editMode, this.context.loggingService.getCorrelationId(), "PeoplePicker", 'PeoplePicker');
      if (myStyle === null) {
        //this.context.loggingService.logCustomerEvent("Test2", this.context.loggingService.getCorrelationId(), "PeoplePicker", 'PeoplePicker');

        switch (this.context.themeName) {
          case 'default':
            myStyle = {
              text: { backgroundColor: 'white', color: this.context.themeV9.colorBrandForeground1 },
              input: { backgroundColor: 'white', color: this.context.themeV9.colorBrandForeground1 },
              itemsWrapper: { backgroundColor: 'white', color: this.context.themeV9.colorBrandForeground1 },
              screenReaderText: { backgroundColor: 'white', color: this.context.themeV9.colorBrandForeground1 },
              root: {
                backgroundColor: 'white', color: this.context.themeV9.colorBrandForeground1
              }
            };
            break;
          default:
            myStyle = {
              text: { backgroundColor: this.context.themeV9.colorNeutralBackground1, color: this.context.themeV9.colorBrandForeground1 },
              input: { backgroundColor: this.context.themeV9.colorNeutralBackground1, color: this.context.themeV9.colorBrandForeground1 },
              itemsWrapper: { backgroundColor: this.context.themeV9.colorNeutralBackground1, color: this.context.themeV9.colorBrandForeground1 },
              screenReaderText: { backgroundColor: this.context.themeV9.colorNeutralBackground1, color: this.context.themeV9.colorBrandForeground1 },
              root: {
                backgroundColor: this.context.themeV9.colorNeutralBackground1, color: this.context.themeV9.colorBrandForeground1
              }
            };
            break;
        }
      }

    } catch (error) {
      //this.context.loggingService.logException(error, SeverityLevel.Error, "", 'PeoplePicker',)
      console.error(error);
    }
    // Renders content
    return (
      <div>
        {this.props.label && <Label required={this.props.isRequired} styles={this.props.peoplePickerLabelStyles ? this.props.peoplePickerLabelStyles : null}>{this.props.label}</Label>}
        <NormalPeoplePicker
          disabled={this.props.disabled}
          pickerSuggestionsProps={suggestionProps}
          onResolveSuggestions={this.onSearchFieldChanged}
          onChange={this.onItemChanged}
          defaultSelectedItems={this.intialPersonas}
          itemLimit={this.props.personSelectionLimit || 999}
          resolveDelay={this.props.deferredValidationTime}
          styles={myStyle ? myStyle : null }
          selectedItems={this.props.selectedItems ? this.props.selectedItems : null }
          />
        <span className={styles.errorMessage}>{this.state.errorMessage}</span>
      </div>
    );
  }
}

