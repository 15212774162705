import { PrincipalType } from "./PrincipalType";
import axios, { AxiosHeaders } from "axios";

/**
 * Defines a People object for the PropertyFieldPeoplePicker
 */
export interface IGroupOrPerson {
  /**
   * Group ID
   */
  id?: string;
  /**
   * Group Description
   */
  description?: string;
  /**
   * User's full name
   */
  fullName: string;
  /**
   * User's login
   */
  login: string;
  /**
   * User's email (optional)
   */
  email?: string;
  /**
   * User's job title (optional)
   */
  jobTitle?: string;
  /**
   * User's initials (optional)
   */
  initials?: string;
  /**
   * User's image url (optional)
   */
  imageUrl?: string;

  objectId?: string;

  lookupId?: string;

  isExternal: boolean;
}

/**
 * Service implementation to search people in SharePoint
 */
export default class SPPeopleSearchService {
  /**
   * Search people from the SharePoint People database
   */
  public searchPeople(
    headers: AxiosHeaders,
    webAbsoluteUrl:string,
    query: string,
    principalType: PrincipalType[],
    resolveAllEmails: boolean
  ): Promise<IGroupOrPerson[]> {
    // If the running env is SharePoint, loads from the peoplepicker web service
    const userRequestUrl: string = `${webAbsoluteUrl}/_api/SP.UI.ApplicationPages.ClientPeoplePickerWebServiceInterface.clientPeoplePickerSearchUser`;
    const data = {
      queryParams: {
        AllowEmailAddresses: resolveAllEmails,
        AllowMultipleEntities: false,
        AllUrlZones: false,
        MaximumEntitySuggestions: 20,
        PrincipalSource: 15,
        // PrincipalType controls the type of entities that are returned in the results.
        // Choices are All - 15, Distribution List - 2 , Security Groups - 4, SharePoint Groups - 8, User - 1.
        // These values can be combined (example: 13 is security + SP groups + users)
        PrincipalType:
          !!principalType && principalType.length > 0
            ? principalType.reduce((a, b) => a + b, 0)
            : 1,
        QueryString: query
      }
    };

    // Do the call against the People REST API endpoint
    return axios
      .post(userRequestUrl, data, { headers: headers })
      .then((searchResponse) => {       
          let res: IGroupOrPerson[] = [];
          const values = JSON.parse(searchResponse.data.value);
          res = values.map(element => {

            let email=element.EntityData.Email;
            if(!email || email.length<1){
              email=element.EntityData.OtherMails;
            }

            switch (element.EntityType) {
              case "User":{
                let isExtenrnal=false;    
                if(element.EntityData && element.EntityData.PrincipalType==="GUEST_USER"){
                  isExtenrnal=true;
                    }
                  const groupOrPerson: IGroupOrPerson = {
                    fullName: element.DisplayText,
                    login: element.Key,
                    email: email,
                    objectId: element.EntityData.ObjectId,
                    jobTitle: element.EntityData.Title,
                    initials: this.getFullNameInitials(element.DisplayText),
                    imageUrl: this.getUserPhotoUrl(
                      element.EntityData.Email,
                      webAbsoluteUrl
                    ),
                    isExternal: isExtenrnal
                  };
                  return groupOrPerson;
              }
              case "SecGroup":{
                const group: IGroupOrPerson = {
                  fullName: element.DisplayText,
                  login: element.Key,
                  description: element.Description,
                  isExternal: false
                };
                return group;
              }
              case "FormsRole":{
                const formsRole: IGroupOrPerson = {
                  fullName: element.DisplayText,
                  login: element.Key,
                  description: element.Description,
                  isExternal: false
                };
                return formsRole;
              }
                
              case "GUEST_USER":{
                const extPerson: IGroupOrPerson = {
                  fullName: element.DisplayText,
                  login: element.Key,
                  email: element.EntityData.Email,
                  objectId: element.EntityData.ObjectId,
                  jobTitle: element.EntityData.Title,
                  initials: this.getFullNameInitials(element.DisplayText),
                  imageUrl: this.getUserPhotoUrl(
                    element.EntityData.Email,
                    webAbsoluteUrl
                  ),
                  isExternal: true
                };
                return extPerson;
              }
              default:
                if(element.EntityData && element.EntityData.PrincipalType==="UNVALIDATED_EMAIL_ADDRESS"){
                  const unvalidatedPersona: IGroupOrPerson = {
                    fullName: element.DisplayText,
                    login: element.Key,
                    email: element.EntityData.Email,
                    objectId: "",
                    id: element.Key,
                    initials: this.getFullNameInitials(element.DisplayText),                    
                    isExternal: true
                  };
                  return unvalidatedPersona;
                }
                else{
                  const persona: IGroupOrPerson = {
                    fullName: element.DisplayText,
                    login: element.EntityData.AccountName,
                    id: element.EntityData.SPGroupID,
                    description: element.Description,
                    isExternal: false
                  };
                  return persona;
                }
            }
          });
        return res;
      }
    );
  }

  /**
   * Generates Initials from a full name
   */
  private getFullNameInitials(fullName: string): string {
    if (!fullName) {
      return fullName;
    }

    const words: string[] = fullName.split(" ");
    if (words.length === 0) {
      return "";
    } else if (words.length === 1) {
      return words[0].charAt(0);
    } else {
      return words[0].charAt(0) + words[1].charAt(0);
    }
  }

  /**
   * Gets the user photo url
   */
  private getUserPhotoUrl(userEmail: string, siteUrl: string): string {
    if (userEmail) {
      return `${siteUrl}/_layouts/15/userphoto.aspx?size=S&accountname=${userEmail}`;
    }
    return null;
  }
}
