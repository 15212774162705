import { MessageItem, MissingMessageItem, TenantMessageItem } from "../../interfaces/IMessageItem";
import moment from "moment";
import { AppConstants } from "../../Constants/AppConstants";
import HttpHeaderHelper from "../../helpers/HttpHeadersHelper";
import axios from "axios";
import { GetUserImpersonationAccessToken } from "../HttpClientService/HttpClientService";

export default class MessageService {
    public static async retrieveMessages(query: string, onlyMicrosoft: boolean, itAdminLevels: string[], appOwnerLevels: string[], complianceLevels: string[], securityLevels: string[], services: string[], monthsToFilter: number, subscriptionKey: string): Promise<MessageItem[]>{
        if(!subscriptionKey || subscriptionKey.length === 0){
            return undefined;
        }

        const messages: MessageItem[] = [];

        try{
            const requestHeaders = HttpHeaderHelper.getHeaders(subscriptionKey, true, false);            

            const rolesFiltersCompilation: string[] = [];

            if(itAdminLevels && itAdminLevels.length > 0){
                rolesFiltersCompilation.push(`search.in(MCITAdmin, '${itAdminLevels.join(',')}')`);
            }

            if(appOwnerLevels && appOwnerLevels.length > 0){
                rolesFiltersCompilation.push(`search.in(MCAppOwner, '${appOwnerLevels.join(',')}')`);
            }

            if(complianceLevels && complianceLevels.length > 0){
                rolesFiltersCompilation.push(`search.in(MCCompliance, '${complianceLevels.join(',')}')`);
            }

            if(securityLevels && securityLevels.length > 0){
                rolesFiltersCompilation.push(`search.in(MCSecurity, '${securityLevels.join(',')}')`);
            }

            let rolesFilters = '';

            if(rolesFiltersCompilation.length > 0){
                rolesFilters = ` and (${rolesFiltersCompilation.join(' or ')})`;
            }

            let servicesFilter = '';

            if(services && services.length > 0){
                servicesFilter = ` and MCServiceTags/any(s: search.in(s, '${services.join(',')}', ','))`;
            }

            let timeSpanFilter = '';

            if(monthsToFilter > 0){
                const cutoffDate = moment().subtract(monthsToFilter, 'months').toISOString();

                timeSpanFilter = ` and MCPublishedDate ge ${cutoffDate}`;
            }

            let queryString = '*';

            if(query && query.length > 0){
                const parts = query.trim().split(' ');

                if(parts.length > 1){
                    queryString = query;
                }else{
                    queryString = `/.*${query.trim()}.*/`;
                } 
            }

            const messageTypeFilter = onlyMicrosoft ? ' and PartitionKey eq \'Microsoft\'' : '';

            let bodyObj = {
                "search": queryString,
                "queryType": "full",
                "filter": `MCIsPublished eq true${messageTypeFilter}${timeSpanFilter}${rolesFilters}${servicesFilter}`,
                "orderby": "MCPublishedDate desc, RowKey desc",
                "top": 100000,
                "skip": 0
            };

            do
            {                
                const accessToken = await GetUserImpersonationAccessToken();

                requestHeaders.set('Authorization', `Bearer ${accessToken}`);

                const responsePost = await axios.post(`${AppConstants.baseApiUrl}${AppConstants.rtcTableIndexUrl}`, bodyObj, { headers: requestHeaders });

                bodyObj = undefined;
    
                if(responsePost.status >= 200 && responsePost.status <= 300){
                    const responseJson = responsePost.data;
    
                    responseJson.value.forEach(element => {   
                        let timelineValue = '';
    
                        try{
                            const timelineJson = JSON.parse(element.MCRollOutDate);
    
                            timelineJson.forEach((timeline) => {
                                timelineValue += `${timeline.SelectedTimeLine}<br/>${timeline.FreeTextTimeLine}<br/><br/>`;
                            });
    
                            timelineValue = `<b style="font-weight: 600">${timelineValue}</b>`;
                        }catch(error){
                            console.error(error);
                        }
    
                        messages.push({
                            messageId: element.RowKey,
                            messageTitle: element.Title,
                            messageRTCTitle: element.MCTitle,
                            messageDescription: element.MCExecutiveSummary,
                            messageDateCreated: moment(element.Timestamp).format("DD.MM.YYYY"),
                            messageDatePublished: moment(element.MCPublishedDate).format("DD.MM.YYYY"),
                            messageDatePublishedRaw: element.MCPublishedDate,
                            messageStatus: 'Inbox', //Retrieved later
                            messageServices: element.MCServiceTags,
                            messageRoadmapId: element.DetailsRoadmapIds,
                            messageComments: [], //Query from Shp List on message rendering
                            messageITAdminAction: element.MCITAdmin,
                            messageAppOwnerAction: element.MCAppOwner,
                            messageSecurityAction: element.MCSecurity,
                            messageComplianceAction: element.MCCompliance,
                            messageITAdminText: element.MCITAdminText,
                            messageAppOwnerText: element.MCAppOwnerText,
                            messageSecurityText: element.MCSecurityText,
                            messageComplianceText: element.MCComplianceText,
                            messageOriginalMSMessage: element.Message,
                            messageCategory: element.MCMessageCategory,
                            messageTimeline: timelineValue,
                            cardExpanded: false,
                            isRead: false //Retrieved later
                        });
                    });
                    
                    if(responseJson["@search.nextPageParameters"] !== undefined){
                        bodyObj = responseJson["@search.nextPageParameters"];
                    }             
                }else{
                    const errorMessage = await responsePost.data.text();
    
                    console.error(errorMessage);
                }
            }while(bodyObj !== undefined);           
        }catch(error){
            console.error(error);
        }
        
        return messages;
    }

    public static async retrieveMessage(messageId: string, subscriptionKey: string): Promise<MessageItem>{
        if(!messageId || messageId.length === 0 || !subscriptionKey || subscriptionKey.length === 0){
            return undefined;
        }

        let message: MessageItem = undefined;

        try{
            const requestHeaders = HttpHeaderHelper.getHeaders(subscriptionKey, false, false);

            const bodyObj = {
                "search": '*',
                "count": true,
                "filter": `PartitionKey eq 'Microsoft' and MCIsPublished eq true and RowKey eq '${messageId}'`,
                "orderby": "RowKey desc"
            };

            const accessToken = await GetUserImpersonationAccessToken();

            requestHeaders.set('Authorization', `Bearer ${accessToken}`);

            const responsePost = await axios.post(`${AppConstants.baseApiUrl}${AppConstants.rtcTableIndexUrl}`, bodyObj, { headers: requestHeaders });

            if(responsePost.status >= 200 && responsePost.status <= 300){
                const responseJson = responsePost.data;

                if(responseJson.value && responseJson.value.length > 0){
                    const element = responseJson.value[0];
                
                    let timelineValue = '';
    
                    try{
                        const timelineJson = JSON.parse(element.MCRollOutDate);
    
                        timelineJson.forEach((timeline) => {
                            timelineValue += `${timeline.SelectedTimeLine}<br/><br/>${timeline.FreeTextTimeLine}<br/><br/>`;
                        });
    
                        timelineValue = `<b>${timelineValue}</b>`;
                    }catch(error){
                        console.error(error);
                    }
    
                    message = {
                        messageId: element.RowKey,
                        messageTitle: element.Title,
                        messageRTCTitle: element.MCTitle,
                        messageDescription: element.MCExecutiveSummary,
                        messageDateCreated: moment(element.Timestamp).format("DD.MM.YYYY"),
                        messageDatePublished: moment(element.MCPublishedDate).format("DD.MM.YYYY"), 
                        messageDatePublishedRaw: element.MCPublishedDate,
                        messageStatus: 'Inbox', //Retrieved later
                        messageServices: element.MCServiceTags,
                        messageRoadmapId: element.DetailsRoadmapIds,
                        messageComments: [], //Query from Shp List on message rendering
                        messageITAdminAction: element.MCITAdmin,
                        messageAppOwnerAction: element.MCAppOwner,
                        messageSecurityAction: element.MCSecurity,
                        messageComplianceAction: element.MCCompliance,
                        messageITAdminText: element.MCITAdminText,
                        messageAppOwnerText: element.MCAppOwnerText,
                        messageSecurityText: element.MCSecurityText,
                        messageComplianceText: element.MCComplianceText,
                        messageOriginalMSMessage: element.Message,
                        messageCategory: element.MCMessageCategory,
                        messageTimeline: timelineValue,
                        cardExpanded: false,
                        isRead: false //Retrieved later
                    };
                }                
            }else{
                const errorMessage = await responsePost.data.text();

                console.error(errorMessage);
            }        
        }catch(error){
            console.error(error);
        }
        
        return message;
    }

    public static async retrieveMissingMessages(subscriptionKey: string, messageIDs: string[], localization: IRTCresources): Promise<MissingMessageItem[]>{
        if(!subscriptionKey || subscriptionKey.length === 0 || !messageIDs || messageIDs.length === 0){
            return undefined;
        }

        const missingMessages: MissingMessageItem[] = [];

        try{
            const requestHeaders = HttpHeaderHelper.getHeaders(subscriptionKey, false, false);

            const bodyObj = {
                select: "RowKey, MCStatus, MCIsPublished",
                filter: `search.in(RowKey,'${messageIDs.join(',')}')`
            };

            const accessToken = await GetUserImpersonationAccessToken();

            requestHeaders.set('Authorization', `Bearer ${accessToken}`);

            const responsePost = await axios.post(`${AppConstants.baseApiUrl}${AppConstants.rtcTableIndexUrl}`, bodyObj, { headers: requestHeaders });
    
            if(responsePost.status >= 200 && responsePost.status <= 300){
                const responseJson = responsePost.data;

                responseJson.value.forEach(element => {
                    let status = localization.WebParts.Dialogs.statusPending;

                    if(!element.MCIsPublished){
                        switch(element.MCStatus){
                            case 'In preparation':
                                status = localization.WebParts.Dialogs.statusInPreparation;
                                break;
                            case 'Delivered':
                                status = localization.WebParts.Dialogs.statusDelivered;
                                break;
                            case 'Review':
                                status = localization.WebParts.Dialogs.statusInReview;
                                break;
                        }
                    }else{
                        status = localization.WebParts.Dialogs.statusDelivered;
                    }                    

                    missingMessages.push({
                        messageId: element.RowKey,
                        messageStatus: status
                    });
                });
            }else{
                const errorMessage = await responsePost.data.text();

                console.error(errorMessage);
            }            
        }catch(error){
            console.error(error);
        }
        
        return missingMessages;
    }

    public static async retrieveTenantMessageItems(partitionKey: string, subscriptionKey: string): Promise<TenantMessageItem[]>{
        if(!partitionKey || partitionKey.length === 0 || !subscriptionKey || subscriptionKey.length === 0){
            return undefined;
        }

        const tenantMessageItems: TenantMessageItem[] = [];

        try{         
            let requestUrl = `${AppConstants.baseApiUrl}${AppConstants.tenantTableUrl}?$filter=PartitionKey%20eq%20'${partitionKey}'`;

            let goOn = false;

            const requestHeaders = HttpHeaderHelper.getHeaders(subscriptionKey, true, false);
            
            const accessToken = await GetUserImpersonationAccessToken();

            requestHeaders.set('Authorization', `Bearer ${accessToken}`);

            do{
                const responseGet = await axios.get(requestUrl, { headers: requestHeaders });

                const partitionHeader = responseGet.headers['x-ms-continuation-NextPartitionKey'];
                const rowHeader = responseGet.headers['x-ms-continuation-NextRowKey'];

                if(partitionHeader && rowHeader){
                    requestUrl = `${AppConstants.baseApiUrl}${AppConstants.tenantTableUrl}?NextPartitionKey=${partitionHeader}&NextRowKey=${rowHeader}`;

                    goOn = true;
                }else{
                    goOn = false;
                }

                if(responseGet.status >= 200 && responseGet.status <= 300){
                    const responseJson = responseGet.data;
    
                    if(responseJson && responseJson.value && responseJson.value.length > 0){
                        responseJson.value.forEach(element => {
                            tenantMessageItems.push(
                                {
                                    messageDate: element.MessageDate,
                                    statusDate: element.StatusDate,
                                    isRead: element.Read,
                                    messageId: element.RowKey,
                                    messageStatus: element.Status,
                                    timeStamp: element.Timestamp
                                }
                            );
                        });
                    }
                }else{
                    const errorMessage = responseGet.data;
    
                    console.error(errorMessage);
                }
            }while(goOn);            
        }catch(error){
            console.error(error);
        }

        return tenantMessageItems;
    }

    public static async retrieveTenantMessageItem(partitionKey: string, messageID: string, subscriptionKey: string): Promise<TenantMessageItem>{
        if(!partitionKey || partitionKey.length === 0 || !messageID || messageID.length === 0 || !subscriptionKey || subscriptionKey.length === 0){
            return undefined;
        }

        let tenantMessageItem: TenantMessageItem = undefined;

        try{         
            const requestUrl = `${AppConstants.baseApiUrl}${AppConstants.tenantTableUrl}?$filter=PartitionKey%20eq%20'${partitionKey}'%20and%20RowKey%20eq%20'${messageID}'`;

            const requestHeaders = HttpHeaderHelper.getHeaders(subscriptionKey, true, false);

            const accessToken = await GetUserImpersonationAccessToken();

            requestHeaders.set('Authorization', `Bearer ${accessToken}`);

            const responseGet = await axios.get(requestUrl, { headers: requestHeaders });

            if(responseGet.status >= 200 && responseGet.status <= 300){
                const responseJson = responseGet.data;

                if(responseJson && responseJson.value && responseJson.value.length > 0){
                    tenantMessageItem = {
                        messageDate: responseJson.value[0].MessageDate,
                        statusDate: responseJson.value[0].StatusDate,
                        isRead: responseJson.value[0].Read,
                        messageId: messageID,
                        messageStatus: responseJson.value[0].Status,
                        timeStamp: responseJson.value[0].Timestamp
                    };
                }
            }else{
                const errorMessage = responseGet.data;

                console.error(errorMessage);
            }
        }catch(error){
            console.error(error);
        }

        return tenantMessageItem;
    }

    public static async createTenantMessageItem(partitionKey: string, rowKey: string, status: string, statusDate: Date, read: boolean, messageDate: Date, subscriptionKey: string): Promise<boolean>{
        if(!partitionKey || partitionKey.length === 0 || !rowKey || rowKey.length === 0 || !status || status.length === 0 || !subscriptionKey || subscriptionKey.length === 0 || !statusDate || !messageDate){
            return false;
        }

        let result = false;
        
        try{
            const messageObj = {
                "StatusDate@odata.type":"Edm.DateTime",
                StatusDate:moment(statusDate).toISOString(),
                "MessageDate@odata.type":"Edm.DateTime",
                MessageDate:moment(messageDate).toISOString(),
                Status:status,
                Read:read,
                PartitionKey:partitionKey,
                RowKey:rowKey 
            };

            const requestHeaders = HttpHeaderHelper.getHeaders(subscriptionKey, false, false);

            const accessToken = await GetUserImpersonationAccessToken();

            requestHeaders.set('Authorization', `Bearer ${accessToken}`);

            const responsePost = await axios.post(`${AppConstants.baseApiUrl}${AppConstants.tenantTableUrl}`, messageObj, { headers: requestHeaders });

            if(responsePost.status >= 200 && responsePost.status <= 300){
                result = true;
            }else{
                const errorMessage = responsePost.data;

                console.error(errorMessage);
            }
        }catch(error){
            console.error(error);
        }

        return result;
    }

    public static async updateTenantMessageItem(partitionKey: string, rowKey: string, status: string, statusDate: Date, read: boolean, messageDate: Date, subscriptionKey: string): Promise<boolean>{
        if(!partitionKey || partitionKey.length === 0 || !rowKey || rowKey.length === 0 || !status || status.length === 0 || !subscriptionKey || subscriptionKey.length === 0 || !statusDate || !messageDate){
            return false;
        }

        let result = false;
        
        try{
            const messageObj = {
                "StatusDate@odata.type":"Edm.DateTime",
                StatusDate:moment(statusDate).toISOString(),
                "MessageDate@odata.type":"Edm.DateTime",
                MessageDate:moment(messageDate).toISOString(),
                Status:status,
                Read:read
            };

            const requestHeaders = HttpHeaderHelper.getHeaders(subscriptionKey, false, true);

            const accessToken = await GetUserImpersonationAccessToken();

            requestHeaders.set('Authorization', `Bearer ${accessToken}`);

            const responsePost = await axios.put(`${AppConstants.baseApiUrl}${AppConstants.tenantTableUrl}/${partitionKey.replace('@','%40')}/${rowKey}`, messageObj, { headers: requestHeaders });

            if(responsePost.status >= 200 && responsePost.status <= 300){
                result = true;
            }else{
                const errorMessage = responsePost.data;

                console.error(errorMessage);
            }
        }catch(error){
            console.error(error);
        }

        return result;
    }

    public static async upsertMessageItem(partitionKey: string, rowKey: string, status: string, statusDate: Date, read: boolean, messageDate: Date, subscriptionKey: string): Promise<boolean>{
        if(!partitionKey || partitionKey.length === 0 || !rowKey || rowKey.length === 0 || !status || status.length === 0 || !subscriptionKey || subscriptionKey.length === 0 || !statusDate || !messageDate){
            return false;
        }

        let result = false;

        try{
            const messageItem = await this.retrieveTenantMessageItem(partitionKey, rowKey, subscriptionKey);

            if(messageItem){
                result = await this.updateTenantMessageItem(partitionKey, rowKey, status, statusDate, read, messageDate, subscriptionKey);
            }else{
                result = await this.createTenantMessageItem(partitionKey, rowKey, status, statusDate, read, messageDate, subscriptionKey);
            }
        }catch(error){
            console.error(error);
        }

        return result;
    }

    public static async retrieveServiceFacets(subscriptionKey: string): Promise<string[]>{
        if(!subscriptionKey || subscriptionKey.length === 0){
            return undefined;
        }

        const services: string[] = [];

        try{

            const requestHeaders = HttpHeaderHelper.getHeaders(subscriptionKey, false, false);
    
            const bodyObj = {
                search: "*",
                facets: ["MCServiceTags, count:99999, sort:value"],
                filter: "PartitionKey eq 'Microsoft' and MCIsPublished eq true",
                select: "PartitionKey"
            };

            const accessToken = await GetUserImpersonationAccessToken();

            requestHeaders.set('Authorization', `Bearer ${accessToken}`);

            const responsePost = await axios.post(`${AppConstants.baseApiUrl}${AppConstants.rtcTableIndexUrl}`, bodyObj, { headers: requestHeaders });

            if(responsePost.status >= 200 && responsePost.status <= 300){
                const responseJson = responsePost.data;

                responseJson['@search.facets'].MCServiceTags.forEach(element => {
                    services.push(element.value);
                });
            }
        }catch(error){
            console.error(error);
        }

        return services;
    }
}