/* eslint-disable dot-notation */
/* eslint-disable @typescript-eslint/no-floating-promises */
import { AppConstants } from '../../Constants/AppConstants';
import HttpHeaderHelper from '../../helpers/HttpHeadersHelper';
import { ILicenseStatus, LicenseType } from '../../interfaces/ILicenseStatus';
import axios from 'axios';
//import { GetGraphAccessToken } from '../HttpClientService/HttpClientService';

export class LicenseService {

    private _accessToken: string;

    constructor(accesToken: string) {
        this._accessToken = accesToken;
    }

    public async getLicenseStatus(subscriptionKey:string): Promise<ILicenseStatus> {
        if(!subscriptionKey || subscriptionKey.length<2){
            return {IsValid: false, Message: 'No subscription key provided'};
        }

        const cachedValueString = window.sessionStorage.getItem("RtCLicense");

        if(cachedValueString && cachedValueString.length > 0){
            const license: ILicenseStatus = JSON.parse(cachedValueString);
            
            return license;
        }else{
            const license = await this.checkLicenseInternal(subscriptionKey);

            if(license.IsValid && !license.Message){
                window.sessionStorage.setItem("RtCLicense", JSON.stringify(license));
            }            

            return license;
        }
    }

    private async checkLicenseInternal(subscriptionKey: string): Promise<ILicenseStatus> {
        try {
            if(!subscriptionKey || subscriptionKey.length === 0){
                throw new Error('No subscription key provided');
            }

            const reqHeaders = HttpHeaderHelper.getHeaders(subscriptionKey, true, false);

            //const accessToken = await GetGraphAccessToken();

            reqHeaders.set('Authorization', `Bearer ${this._accessToken}`);

            const resp = await axios.get(`${AppConstants.baseApiUrl}${AppConstants.licenseCheckUrl}`, { headers: reqHeaders });

            if (resp.status === 200) {
                const result = resp.data;

                const licenseTypeMap: { [key: string]: LicenseType } = {
                    'Standard': LicenseType.Standard,
                    'Compliance': LicenseType.Compliance
                };

                const licenseType = licenseTypeMap[result.LicenseType] || LicenseType.Basic;

                return { IsValid: true, Type: licenseType };
            }

            if (resp.status === 401) {
                return { IsValid: false, Message: 'Invalid subscription key' };
            }

            return { IsValid: true, Type: LicenseType.Basic, Message: 'No license found, please contact MCAG support' };
        } catch (error) {
            console.error("RtC License Check failed:", error);

            return { IsValid: false, Message: 'License check failed' };
        }
    }
}