import { AxiosHeaders } from "axios";

export default class HttpHeaderHelper {
    public static getHeaders(subscriptionKey: string, minimalAccept: boolean, includeIfMatch: boolean, accessToken?: string): AxiosHeaders{
        const requestHeaders: AxiosHeaders = new AxiosHeaders();

        requestHeaders.set('Content-type', 'application/json;charset=utf-8');
        
        if(subscriptionKey && subscriptionKey.length > 0){
            requestHeaders.set('Ocp-Apim-Subscription-Key', subscriptionKey);
        }

        if(minimalAccept){
            requestHeaders.set('Accept', 'application/json;odata=nometadata;charset=utf-8');
        }else{
            requestHeaders.set('Accept', 'application/json;charset=utf-8');
        }

        if(includeIfMatch){
            requestHeaders.set('If-Match', '*');
        }

        if(accessToken && accessToken.length > 0){
            requestHeaders.set('Authorization', `Bearer ${accessToken}`);
        }
        
        return requestHeaders;
    }
}