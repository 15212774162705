export interface IDropdownOption {
    key: string;
    label: string;
  }

export interface IMentionOption extends IDropdownOption {
    type: MentionType;
}
 
export enum MentionType {
    Team = 0,
    Channel = 1,
    Member = 2,
    Tag = 3
}